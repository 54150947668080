import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setCurrentCompany } from 'reducers/auth';
// api
import { getTenantSettings, updateTenantSettings } from 'endpoints/tenantSettings';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import pick from 'lodash.pick';
import moment from 'moment';
import { formatBackEndWeightToSystem } from 'utils/weightHelper';
import { showToastrMessage } from 'utils';
import { isSchedulingTariffUser } from 'utils/userHelper';
// components
import Preloader from 'components/Preloader';
import { FormattedMessage } from 'react-intl';
import Subnavigation from 'components/Subnavigation';
// styles
import './Preferences.scss';

const Preferences = ({ children, setCurrentCompany, isSchedulingAccessOnly, router, location }) => {
  const [tenant_settings, setTenantSettings] = useState({
    settings: {
      measurement_system: 'imperial',
      temperature_system: 'fahrenheit',
      sms_compliance: false,
      inventory_reconciliation_active: true,
      inventory_reconciliation: 'mon',
      auto_report_emails: false,
      auto_report_period: 'weekly',
      auto_report_mortality_percentage: false,
      withdrawal_tracking_on: false,
      comment_company_direct_post: false,
      withdrawal_weight_limit: 200,
      new_withdrawal_weight_limit: 200,
      track_mortality_reasons: false,
      company_withdrawal_warning_recipients: [],
      water_consumption_monitoring: false,
      water_consumption_drop_limit: 0,
      barnsheets_limited_access: false,
      surveys: [{}],
      scheduling_access: true,
      media_upload: true,
      treatment_compliance: false,
      treatment_compliance_days: 14,
      new_treatment_compliance_days: 14,
      symptom_prompt: false,
      symptom_prompt_days: 3,
      new_symptom_prompt_days: 3,
      abf_tracking: false,
      feed_orders_tracking: false,
    },
    isLoading: false,
    isLoaded: false,
  });
  const [waterPercents, setPercent] = useState();

  const navLinks = [
    {
      link: '/admin/preferences/settings',
      label: <FormattedMessage id="general.settings" />,
      isHidden: isSchedulingAccessOnly,
    },
    {
      link: '/admin/preferences/daily-checkup',
      label: <FormattedMessage id="general.dailyCheckup" />,
      isHidden: isSchedulingAccessOnly,
    },
    {
      link: '/admin/preferences/integrations',
      label: <FormattedMessage id="general.integrations" />,
    },
  ];

  const setSettings = (data) => {
    const settings = pick(data, ['sms_compliance', 'inventory_reconciliation', 'auto_report_emails',
      'auto_report_period', 'withdrawal_tracking_on', 'withdrawal_weight_limit', 'track_mortality_reasons',
      'comment_company_direct_post', 'temperature_system', 'company_withdrawal_warning_recipients',
      'measurement_system', 'surveys', 'custom_survey', 'track_water_usage', 'track_temperature',
      'water_consumption_monitoring', 'water_consumption_drop_limit', 'map_centralize', 'map_lat', 'map_lng',
      'map_zoom', 'treatment_protocols', 'treatment_protocol_contacts',
      'barnsheets_limited_access', 'farm_compliance_hour', 'farm_compliance_utc_offset',
      'company_analytics_users', 'company_svrs_disabled_users', 'analytics_extended_access', 'svrs_limited_access',
      'auto_report_mortality_percentage', 'treatment_protocols_pg_compliance', 'svrs_compliance_emails',
      'farms_compliance_emails', 'media_upload', 'general_water_usage_warnings', 'general_withdrawal_warnings',
      'dc_migrations_limited_access', 'svrs_daily_summary_emails', 'svrs_summary_emails', 'dc_mortality_media_prompt',
      'treatment_compliance', 'treatment_compliance_days', 'symptom_prompt', 'symptom_prompt_days', 'abf_tracking',
      'closeout_emails', 'company_closeout_email_users', 'feed_orders_tracking'
    ]);
    settings.inventory_reconciliation_active = settings.inventory_reconciliation !== 'disabled';
    settings.new_treatment_compliance_days = settings.treatment_compliance_days;
    settings.new_symptom_prompt_days = settings.symptom_prompt_days;
    settings.new_withdrawal_weight_limit = formatBackEndWeightToSystem(
      settings.withdrawal_weight_limit,
      data.measurement_system
    );
    if (!settings.inventory_reconciliation_active) settings.inventory_reconciliation = 'mon';
    setCurrentCompany({
      auto_report_period: data.auto_report_period,
      auto_report_mortality_percentage: data.auto_report_mortality_percentage,
      withdrawal_tracking_on: data.withdrawal_tracking_on,
      track_mortality_reasons: data.track_mortality_reasons,
      comment_company_direct_post: data.comment_company_direct_post,
      temperature_system: data.temperature_system,
      measurement_system: data.measurement_system,
      track_water_usage: data.track_water_usage,
      track_temperature: data.track_temperature,
      map_centralize: data.map_centralize,
      map_lat: data.map_lat,
      map_lng: data.map_lng,
      map_zoom: data.map_zoom,
      treatment_protocols: data.treatment_protocols,
      barnsheets_limited_access: data.barnsheets_limited_access,
      farm_compliance_hour: data.farm_compliance_hour,
      farm_compliance_utc_offset: data.farm_compliance_utc_offset,
      company_svrs_disabled_users: data.company_svrs_disabled_users,
      company_analytics_users: data.company_analytics_users,
      treatment_protocols_pg_compliance: data.treatment_protocols_pg_compliance,
      svrs_compliance_emails: data.svrs_compliance_emails,
      farms_compliance_emails: data.farms_compliance_emails,
      media_upload: data.media_upload,
      general_water_usage_warnings: data.general_water_usage_warnings,
      general_withdrawal_warnings: data.general_withdrawal_warnings,
      dc_migrations_limited_access: data.dc_migrations_limited_access,
      treatment_compliance: data.treatment_compliance,
      treatment_compliance_days: data.treatment_compliance_days,
      symptom_prompt: data.symptom_prompt,
      symptom_prompt_days: data.symptom_prompt_days,
      abf_tracking: data.abf_tracking,
      company_closeout_email_users: data.company_closeout_email_users,
      feed_orders_tracking: data.feed_orders_tracking,
    });

    setTenantSettings({
      settings: {
        ...settings,
        surveys: settings.surveys ?
          settings.surveys.map((survey) => ({
            ...survey, start_date: moment(survey.start_date).format('MM/DD/YYYY')
          })) :
          [],
        withdrawal_weight_limit: formatBackEndWeightToSystem(settings.withdrawal_weight_limit, data.measurement_system),
      },
      isLoaded: true,
      isLoading: false,
    });

    setPercent(settings.water_consumption_drop_limit || 10);
  };

  useEffect(() => {
    const redirectPath = '/admin/preferences/integrations';
    if (isSchedulingAccessOnly && location.pathname !== redirectPath) router.push(redirectPath);

    setTenantSettings({
      ...tenant_settings,
      isLoading: true,
    });
    getTenantSettings()
      .then((data) => {
        setSettings(data);
      });
  }, []);

  const handleSetSettings = (data) => {
    setTenantSettings({
      ...tenant_settings,
      settings: {
        ...tenant_settings.settings,
        ...data,
      }
    });
  };

  const updateSettings = (resource) => {
    setTenantSettings({ ...tenant_settings, isLoading: true });
    updateTenantSettings(resource)
      .then((data) => {
        const [resourceKey] = Object.keys(resource);
        if (resourceKey === 'water_consumption_drop_limit') {
          showToastrMessage('component.setting.waterConsumptionMonitoringSaved');
        }
        setSettings(data);
      })
      .catch((errors) => {
        setTenantSettings({ ...tenant_settings, isLoading: false });
        return toastResponseErrors(errors);
      });
  };

  const childrenWithProps = React.Children.map(children, (child) => child && React.cloneElement(child,
    {
      tenant_settings: tenant_settings.settings,
      waterPercents,
      updateWaterPercent: setPercent,
      updateSettings,
      setSettings: handleSetSettings,
    }));

  return (
    <div className="Preferences">
      <Subnavigation links={navLinks} className="small-12 column" />
      <section className="small-12 column">
        <Preloader isActive={tenant_settings.isLoading} />
        {tenant_settings.isLoaded && childrenWithProps}
      </section>
    </div>
  );
};

Preferences.propTypes = {
  children: T.node,
  setCurrentCompany: T.func.isRequired,
  isSchedulingAccessOnly: T.bool,
  router: T.object.isRequired,
  location: T.object.isRequired,
};

export default connect((state) => ({
  isSchedulingAccessOnly: isSchedulingTariffUser(state.auth.user),
}), { setCurrentCompany })(Preferences);
