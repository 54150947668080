import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { reportFeedOrder } from 'reducers/dailyCheckup/checkupEdit';
// components
import RangeInput from 'components/RangeInput/RangeInput';
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import SingleDatePicker from 'components/DatePicker/SingleDatePicker/SingleDatePicker';
import Button from 'components/Button';
import CenterBox from 'components/CenterBox';
import CommentBox from 'components/CommentBox';
import StickyHeader from 'components/StickyHeader/StickyHeader';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import ReactSelect from 'components/Select/ReactSelect';
// utils
import { animateScroll } from 'react-scroll';
import cn from 'classnames';
import moment from 'moment';
import { convertLbsToTons } from 'utils/weightHelper';
import { formatNumber } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getCommentByRole } from 'helpers.js';
// router
import { withRouter } from 'react-router';
// endpoints
import { getFarmBins } from 'endpoints/farms';
// constants
import { comment_roles } from 'constants.js';
// styles
import './DailyCheckupOrderFeed.scss';
import classnames from 'classnames';

const defaultBin = { id: null, bin_id: null, quantity: 0 };

const DailyCheckupOrderFeed = ({
  checkup,
  setPageOptions,
  params,
  backLink,
  validateCheckup,
  reportFeedOrder,
  isReportingFeedOrder,
  router,
}) => {
  const getInitialValues = (checkup) => {
    const {
      feed_order_bins = [],
      feed_order_delivery_date = null,
    } = checkup;

    const { FEED_ORDER_ROLE } = comment_roles;
    const { comment, mentioned_user_ids } = getCommentByRole(checkup.comments || [], FEED_ORDER_ROLE);

    return {
      bins: feed_order_bins.length > 0
        ? feed_order_bins.map((feedOrderBin) => ({
          id: feedOrderBin.id,
          bin_id: feedOrderBin.bin.id,
          name: feedOrderBin.bin.name,
          quantity: parseFloat(feedOrderBin.quantity),
        }))
        : [defaultBin],
      deliveryDate: feed_order_delivery_date ? moment(feed_order_delivery_date) : null,
      comment: comment || '',
      mentionsIds: mentioned_user_ids || [],
    };
  };


  const initialValues = getInitialValues(checkup);

  const [state, setState] = useState({
    comment: initialValues.comment,
    mentionsIds: initialValues.mentionsIds,
  });

  const [destroyedBins, setDestroyedBins] = useState([]);
  const [bins, setBins] = useState(initialValues.bins);
  const [deliveryDate, setDeliveryDate] = useState(initialValues.deliveryDate);
  const [binsOptions, setBinsOptions] = useState([]);

  const fetchFarmBins = (farmId) => {
    getFarmBins(farmId)
      .then((bins) => {
        setBinsOptions(bins);
      })
      .catch(toastResponseErrors);
  };

  useEffect(() => {
    if (checkup.id === parseInt(params.id, 10)) {
      setPageOptions({
        backLink: { icon: 'fa fa-times fs-18', link: backLink },
        mobileTitle: 'feedOrder',
      });
    }

    if (checkup.pig_group) {
      fetchFarmBins(checkup.pig_group.farm.id);
    }

    return () => {
      setPageOptions({ mobileTitle: '' });
    };
  }, []);

  useEffect(() => {
    const initialValues = getInitialValues(checkup);

    setState({
      comment: initialValues.comment,
      mentionsIds: initialValues.mentionsIds,
    });

    setBins(initialValues.bins);
    setDestroyedBins([]);
    setDeliveryDate(initialValues.deliveryDate);

    setPageOptions({
      backLink: { icon: 'fa fa-times fs-18', link: backLink },
      mobileTitle: 'feedOrder',
    });

    if (checkup.pig_group) {
      fetchFarmBins(checkup.pig_group.farm.id);
    }

    return () => {
      setPageOptions({ mobileTitle: '' });
    };
  }, [checkup]);


  const onCommentOpen = () => {
    animateScroll.scrollToBottom({ containerId: 'bodybag' });
  };

  const onDateChange = (date) => {
    setDeliveryDate(date);
  };

  const submitData = () => {
    if (!validateCheckup(checkup)) return;

    const { comment, mentionsIds } = state;
    const allBins = [...bins, ...destroyedBins];

    const resource = {
      feed_order_delivery_date: deliveryDate ? deliveryDate.format('YYYY-MM-DD') : null,
      feed_order_bins: allBins.map((bin) => ({
        id: bin.id,
        bin_id: bin.bin_id,
        quantity: bin.quantity,
        _destroy: bin._destroy,
      })),
      comments_feed_order: {
        text: comment,
        mentioned_user_ids: mentionsIds,
      },
    };

    reportFeedOrder(checkup.id, { resource })
      .then(() => {
        router.push(backLink);
      })
      .catch(toastResponseErrors);
  };

  const isContinueDisabled = () => {
    return (
      !deliveryDate ||
      bins.some(({ bin_id, quantity }) => !bin_id?.toString() || !quantity)
    );
  };

  const removeBin = (index) => {
    setBins((prevBins) => {
      const binToRemove = prevBins[index];
      const newBins = [...prevBins.slice(0, index), ...prevBins.slice(index + 1)];

      if (binToRemove.id) {
        setDestroyedBins((prevDestroyedBins) => [
          ...prevDestroyedBins,
          { ...binToRemove, _destroy: true },
        ]);
      }

      return newBins;
    });
  };

  const onBinSelect = (index) => ({ id, name }) => {
    const bin = bins[index];
    setBins((prevState) => [
      ...prevState.slice(0, index),
      { ...bin, bin_id: id, name },
      ...prevState.slice(index + 1),
    ]);
  };

  const onBinAdd = () => setBins((prevBins) => [...prevBins, defaultBin]);

  const setBinData = (index) => (key, value) => {
    setBins((prevState) => [
      ...prevState.slice(0, index),
      { ...prevState[index], [key]: value },
      ...prevState.slice(index + 1)
    ]);
  };

  const { comment, mentionsIds } = state;
  const totalOrderQuantity = bins.reduce((acc, bin) => acc + bin.quantity, 0);
  const hideTrash = (bins.length) === 1 && !bins[0].id; // hide trash if last row not saved

  return (
    <CenterBox
      className="DailyCheckupOrderFeed"
      isLoading={false}
      renderIf={Boolean(checkup)}
    >
      <StickyHeader className="hide-for-large">
        <TotalQuantityLine mobile value={totalOrderQuantity} />
      </StickyHeader>

      <section className="center-box-header show-for-large">
        <div>
          <div className="subtitle">{checkup.pig_group_name}</div>
          <h1 className="title">
            <FormattedMessage id="general.feedOrder" />
          </h1>
        </div>
        <Link to={backLink}>
          <i className="fa fa-times close-center-box" />
        </Link>
      </section>

      <TotalQuantityLine mobile value={totalOrderQuantity} className="show-for-large" />

      <section className="center-box-body">
        <div className="migrations-form">
          {bins
            .map((bin, index) => (
              <div className="block" key={`migration-${index}`}>
                <div className="input-row">
                  <div className="index-cell">
                    <div className="row-index">{index + 1}</div>
                  </div>
                  <div className="input-group">
                    <ReactSelect
                      filterOptions={(options) => {
                        const selectedBinIds = bins
                          .filter((_, i) => i !== index)
                          .map((bin) => bin.bin_id);
                        return options.filter((option) => !selectedBinIds.includes(option.id));
                      }}
                      className="select-wrapper"
                      placeholder={<FormattedMessage id="general.selectBin" />}
                      options={binsOptions}
                      valueKey="id"
                      labelKey="name"
                      value={bins[index]?.bin_id}
                      onChange={onBinSelect(index)}
                      noInputMargin
                      searchable={false}
                    />
                    <div className="input-group__item range-input">
                      <span className="input-label-group">
                        <span className="input-label"><FormattedMessage id="general.orderQuantity" /></span>
                        <span className="bin-quantity-label">
                          <b>
                            {formatNumber(bins[index].quantity)}&nbsp;
                            <FormattedMessage id="general.weight.lbs" />
                          </b>
                          <small>
                            &nbsp;(<FormattedMessage
                              id="general.tonsValue"
                              values={{ value: formatNumber(convertLbsToTons(bins[index].quantity)) }}
                            />)
                          </small>
                        </span>
                      </span>
                      <div className="range-container">
                        <RangeInput
                          step={1000}
                          max={30000}
                          label={<FormattedMessage id="general.weight.lbs" />}
                          value={bins[index].quantity}
                          onChange={(value) => setBinData(index)('quantity', value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classnames('trash-cell', { 'hidden': hideTrash })}>
                    <i className="fa fa-trash-o trash-icon" onClick={() => removeBin(index)} />
                  </div>
                </div>
              </div>
            ))}
        </div>
        <button className="add-bin-button" onClick={onBinAdd}>
          <FormattedMessage id="general.addAnotherBin" />
        </button>
        <div className="input-group__item">
          <span className="input-label"><FormattedMessage id="general.deliveryDate" /></span>
          <SingleDatePicker
            inputId="deliveryDateInput"
            key={`deliveryDate-${deliveryDate ? deliveryDate.format('YYYY-MM-DD') : 'null'}`}
            className="date-input"
            date={deliveryDate}
            onDateChange={onDateChange}
            inputLabel="MM/DD/YYYY"
            isOutsideRange={(day) => !day.isAfter(moment(), 'day')}
            enableOutsideDays
            numberOfMonths={1}
          />
        </div>
        <CommentBox
          hasMentions
          disabledIds={mentionsIds}
          entityId={checkup?.pig_group?.farm_id}
          entityType="farm"
          onOpen={onCommentOpen}
          onValueChange={(comment) => setState((prevState) => ({ ...prevState, comment }))}
          onMentionsChange={(mentions) => {
            setState((prevState) => ({ ...prevState, mentionsIds: mentions.map((i) => i.id) }));
          }}
          value={comment}
          isOpen={!!comment}
          placeholder=""
          useSpeechToText
        />
      </section>

      <section className="center-box-footer show-for-large">
        <div className="buttons text-right">
          <Link className="button margin-right-1" to={backLink}>
            <FormattedMessage id="general.button.cancel" />
          </Link>
          <Button
            primary
            disabled={isContinueDisabled()}
            className="submit wider"
            onClick={submitData}
          >
            <FormattedMessage id="general.button.continue" />
          </Button>
        </div>
      </section>
      <StickyFooter className="hide-for-large" hidden={isContinueDisabled()}>
        <Button
          stickyFooter
          primary
          disabled={isContinueDisabled()}
          onClick={submitData}
        >
          <FormattedMessage id="general.button.continue" />
        </Button>
      </StickyFooter>
    </CenterBox>
  );
};

const TotalQuantityLine = ({ value, mobile, className }) => {
  return (
    <div className={cn(`total-quantity-line ${className}`, { 'mobile': mobile })}>
      <span className="left-value">
        <span className="icon fa fa-ep-piggy" />
        <FormattedMessage id="general.totalOrder" />
      </span>
      <span>
        <strong>
          {formatNumber(value)} <FormattedMessage id="general.weight.lbs" />
        </strong>
        <small>
          &nbsp;(<FormattedMessage id="general.tonsValue" values={{ value: formatNumber(convertLbsToTons(value)) }} />)
        </small>
      </span>
    </div>
  );
};

TotalQuantityLine.propTypes = {
  value: T.number,
  mobile: T.bool,
  className: T.string,
};

DailyCheckupOrderFeed.contextTypes = {
  router: T.object.isRequired
};

DailyCheckupOrderFeed.propTypes = {
  checkup: T.object,
  setPageOptions: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  params: T.object,
  backLink: T.oneOfType([T.string, T.object]),
  reportFeedOrder: T.func.isRequired,
};

export default withRouter(connect(
  (state) => ({
    isReportingFeedOrder: state.dailyCheckup.checkupEdit.isReportingFeedOrder,
  }),
  {
    setPageOptions,
    reportFeedOrder,
  }
)(DailyCheckupOrderFeed));
