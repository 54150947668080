import { fetchFromAPI } from 'utils/api';
import {
  isFakeId,
  isAssetNotUploadedToServer,
  deleteCheckupOffline,
  deleteRequest,
  deleteMediaRequest,
  deleteAssetFromMediaRequest,
  deleteSavedAssetFromMediaRequest,
  farmWhenOneCheckupSubmitted,
  getAllRequests,
  getAllMediaRequests,
  getMediaRequestsByCheckup,
  getFarmOffline,
  getCheckupOffline,
  transformComment,
  updateCheckupOffline,
  updateCheckupWithMigrations,
  updateCheckupWithDeaths,
  updateCheckupWithTreatments,
  updateCheckupWithSymptoms,
  updateCheckupWithTemps,
  updateCheckupWithWaterUsage,
  updateFarmOffline,
  updatePigGroupOffline,
  updateRequestWithData,
} from 'utils/offlineHelper';
import { currentUtcOffsetTime } from 'utils/timeHelper';
import { uploadAssetToCheckup } from 'utils/resumableFileUpload';

export const getCheckupChunkAsset = (checkupId, params) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/chunk/asset`, { params })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const deleteCheckupAsset = (checkupId, assetId) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/assets/${assetId}`, { method: 'delete' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateCheckupAsset = (checkupId, assetId, resource) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/assets/${assetId}`, {
    method: 'put',
    body: JSON.stringify({ resource }),
  })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const deleteCheckupAssetOffline = (checkupId, assetId) => {
  return getCheckupOffline(checkupId)
    .then((checkup) => {
      if (isAssetNotUploadedToServer(assetId)) {
        deleteAssetFromMediaRequest(checkupId, assetId);
      } else {
        deleteSavedAssetFromMediaRequest(checkup, assetId);
      }
      const newCheckup = {
        ...checkup,
        dc_assets: checkup.dc_assets.filter((asset) => asset.id !== assetId),
      };
      updateCheckupOffline(newCheckup);
      return newCheckup;
    })
    .catch((response) => {
      throw response;
    });
};

export const getCheckup = (checkupId, isEditMode) => {
  const path = isEditMode
    ? `/daily_checkups/${checkupId}/edit_mode_show`
    : `/daily_checkups/${checkupId}`;
  return fetchFromAPI(path)
    .then((response) => {
      updateCheckupOffline(response.resource);
      return response.resource;
    })
    .catch((response) => {
      throw response;
    });
};

export const getCheckupLogs = (checkupId) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/logs`)
    .then((response) => response.resources)
    .catch((response) => {
      throw response;
    });
};

export const reportMigrations = (checkupId, data) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/pig_migrations`, { method: 'put', body: JSON.stringify(data) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const reportDeadPigs = (checkupId, data) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/dead_pigs`, { method: 'put', body: JSON.stringify(data) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const reportTreatmentProducts = (checkupId, data) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/treatments`, {
    method: data.all_good ? 'post' : 'put',
    body: JSON.stringify(data),
  })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const reportCheckupSymptoms = (checkupId, data) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/symptoms`, {
    method: data.all_good ? 'post' : 'put',
    body: JSON.stringify(data),
  })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const reportFeedOrderAPI = (checkupId, data) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/feed_orders`, {
    method: data.all_good ? 'post' : 'put',
    body: JSON.stringify(data),
  })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateCheckupTreatmentEfficacy = (checkupId, efficacyId, resource) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/treatment_efficacy_responses/${efficacyId}`, {
    method: 'put',
    body: JSON.stringify({ resource }),
  });
};

export const updateCheckupCorrections = (checkupId, resource) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/corrections`, {
    method: 'put',
    body: JSON.stringify({ resource }),
  });
};

export const updateCheckupAlert = (checkupId, alertId, resource) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/alerts/${alertId}`, {
    method: 'PUT',
    body: resource instanceof FormData ? resource : JSON.stringify({ resource }),
  });
};

export const updateCheckupSurveys = (checkupId, resource) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/surveys`, { method: 'put', body: JSON.stringify({ resource }) });
};

export const reportTemps = (checkupId, data) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/temperature`, { method: 'put', body: JSON.stringify(data) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const reportWater = (checkupId, data) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/water_usage`, { method: 'put', body: JSON.stringify(data) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const reportCheckupSVRQuestionAnswer = (checkupId, svrId, resource) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/svrs/${svrId}`, {
    method: 'put',
    body: JSON.stringify({ resource }),
  }).then((response) => response.resource);
};

export const addCheckupComment = (checkupId, data) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/notes`, { method: 'put', body: JSON.stringify(data) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const submitDailyCheckup = (checkupId) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/submit`, { method: 'put' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const reportMigrationsOffline = (checkupId, data) => {
  return getCheckupOffline(checkupId)
    .then((resource = {}) => {
      const checkup = updateCheckupWithMigrations(resource, data);
      const dataResource = data.all_good
        ? { migrations_all_good: true }
        : { ...data.resource, migrations_all_good: false };
      updateCheckupOffline(checkup);
      updateRequestWithData(checkup, dataResource);
      return checkup;
    })
    .catch((response) => {
      throw response;
    });
};

export const reportDeadPigsOffline = (checkupId, data) => {
  return getCheckupOffline(checkupId)
    .then((resource = {}) => {
      const checkup = updateCheckupWithDeaths(resource, data);
      const dataResource = data.all_good
        ? { death_all_good: true }
        : { ...data.resource, death_all_good: false };
      updateCheckupOffline(checkup);
      updateRequestWithData(checkup, dataResource);
      return checkup;
    })
    .catch((response) => {
      throw response;
    });
};

export const reportTreatmentProductsOffline = (checkupId, data) => {
  return getCheckupOffline(checkupId)
    .then((resource = {}) => {
      const checkup = updateCheckupWithTreatments(resource, data);
      const dataResource = data.all_good
        ? { treatments_all_good: true }
        : { ...data.resource, treatments_all_good: false };
      updateCheckupOffline(checkup);
      updateRequestWithData(checkup, dataResource);
      return checkup;
    })
    .catch((response) => {
      throw response;
    });
};

export const reportCheckupSymptomsOffline = (checkupId, data) => {
  return getCheckupOffline(checkupId)
    .then((resource = {}) => {
      const checkup = updateCheckupWithSymptoms(resource, data);
      const dataResource = data.all_good
        ? { symptoms_all_good: true }
        : { ...data.resource, symptoms_all_good: false };
      updateCheckupOffline(checkup);
      updateRequestWithData(checkup, dataResource);
      return checkup;
    })
    .catch((response) => {
      throw response;
    });
};

export const reportTempsOffline = (checkupId, data) => {
  return getCheckupOffline(checkupId)
    .then((resource = {}) => {
      const checkup = updateCheckupWithTemps(resource, data);
      const dataResource = data.all_good
        ? { temperature_all_good: true }
        : { ...data.resource, temperature_all_good: false };
      updateCheckupOffline(checkup);
      updateRequestWithData(checkup, dataResource);
      return checkup;
    })
    .catch((response) => {
      throw response;
    });
};

export const reportWaterOffline = (checkupId, data) => {
  return getCheckupOffline(checkupId)
    .then((resource = {}) => {
      const checkup = updateCheckupWithWaterUsage(resource, data);
      const dataResource = data.all_good
        ? { water_usage_all_good: true }
        : { ...data.resource, water_usage_all_good: false };
      updateCheckupOffline(checkup);
      updateRequestWithData(checkup, dataResource);
      return checkup;
    })
    .catch((response) => {
      throw response;
    });
};

export const addCheckupCommentOffline = (checkupId, data) => {
  return getCheckupOffline(checkupId)
    .then((resource = {}) => {
      const comments = transformComment(resource.comments, data.resource, 'checkup');
      const checkup = { ...resource, comments };
      updateCheckupOffline(checkup);
      updateRequestWithData(checkup, data.resource);
      return checkup;
    })
    .catch((response) => {
      throw response;
    });
};

export const submitDailyCheckupOffline = (checkupId) => {
  return getCheckupOffline(checkupId)
    .then(async (resource = {}) => {
      const checkup = { ...resource, submitted: true };
      const farm = await getFarmOffline(checkup.farm_id || checkup.pig_group.farm.id);
      const updatedGroup = {
        id: checkup.pig_group_id,
        current_checkup_submitted: true,
      };
      const updatedFarm = farmWhenOneCheckupSubmitted(farm);
      updatePigGroupOffline(updatedGroup);
      updateFarmOffline(updatedFarm);
      return checkup;
    })
    .catch((response) => {
      throw response;
    });
};

export const createDraftCheckups = (pigGroupId, resource) => {
  return fetchFromAPI(`/daily_checkups/offline_sync/pig_groups/${pigGroupId}/draft_checkups`, {
    method: 'post',
    body: JSON.stringify({ resource })
  })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};


export const execDailyCheckupsSync = () => {
  return getAllRequests()
    .then((requests) => {
      return requests.map(async (request) => {

        if (!currentUtcOffsetTime(request.utc_offset).isSame(request.created_on, 'day')) {
          await deleteRequest(request.id);
          await deleteMediaRequest(request.current_checkup_id);
          await deleteCheckupOffline(request.current_checkup_id);
          return Promise.resolve(null);
        }

        return createDraftCheckups(request.pig_group_id, request.resource)
          .then(async (resource = {}) => {
            await updateCheckupOffline(resource);
            await updatePigGroupOffline({
              id: request.pig_group_id,
              current_checkup_id: resource.id,
              current_checkup_created_on: resource.created_on,
              current_checkup_submitted: true,
            });
            if (isFakeId(request.current_checkup_id)) {
              deleteCheckupOffline(request.current_checkup_id);
            }
            const mediaRequests = await getMediaRequestsByCheckup(request.current_checkup_id) || [];
            deleteRequest(request.id);
            return Promise.all(mediaRequests.map(async ({ id, asset, destroy }) => {
              if (destroy) return deleteMediaRequest(id);

              const item = await uploadAssetToCheckup(resource.id, asset.file);
              if (asset.isAudio && asset.description) {
                return updateCheckupAsset(resource.id, item.id, { description: asset.description })
                  .then(() => deleteMediaRequest(id));
              }
              return deleteMediaRequest(id);
            }));
          });
      });
    })
    .catch((response) => {
      throw response;
    });
};

export const execMediaSync = async () => {
  const mediaRequests = await getAllMediaRequests() || [];
  return Promise.all(
    mediaRequests.map(async ({ id, checkup_id, asset, destroy, created_on, utc_offset }) => {

      if (!currentUtcOffsetTime(utc_offset).isSame(created_on, 'day')) {
        await deleteMediaRequest(checkup_id);
        return Promise.resolve();
      }

      if (isFakeId(checkup_id)) return Promise.resolve();

      if (destroy) {
        await deleteCheckupAsset(checkup_id, asset.id);
        return deleteMediaRequest(id);
      }

      const item = await uploadAssetToCheckup(checkup_id, asset.file);
      if (asset.isAudio && asset.description) {
        return updateCheckupAsset(checkup_id, item.id, { description: asset.description })
          .then(() => deleteMediaRequest(id));
      }
      return deleteMediaRequest(id);
    })
  );
};
