import React, { useState, useEffect, useRef } from 'react';
import T from 'prop-types';
// components
import Draggable from 'react-draggable';
// utils
import classnames from 'classnames/bind';
import getValue from 'lodash.get';
// styles
import './RangeInput.module.scss';
import styles from './RangeInput.module.scss';

const cn = classnames.bind(styles);

const RangeInput = ({ value, onChange, step, max, label }) => {
  const [progressWidth, setProgressWidth] = useState(0);
  const bar = useRef(null);

  useEffect(() => {
    // Function to update width dynamically
    const updateWidth = () => {
      const currentWidth = getValue(bar.current, 'offsetWidth', 0);
      setProgressWidth(currentWidth);
    };

    // Create a ResizeObserver to watch for changes
    const resizeObserver = new ResizeObserver(updateWidth);
    if (bar.current) resizeObserver.observe(bar.current);

    // Initial width setup
    updateWidth();

    // Cleanup observer
    return () => {
      if (bar.current) resizeObserver.unobserve(bar.current);
    };
  }, []);

  const snapToStep = (rawValue) => {
    return Math.round(rawValue / step) * step;
  };

  const onClick = (event) => {
    if (!progressWidth) return;

    const x = event.clientX - bar.current.getBoundingClientRect().left;
    let rawValue = (x / progressWidth) * max;
    let newValue = snapToStep(rawValue);

    newValue = Math.max(0, Math.min(max, newValue)); // Clamp within 0 and max
    onChange(newValue);
  };

  const handleChange = (event, { x }) => {
    if (!progressWidth) return;

    let rawValue = (x / progressWidth) * max;
    let newValue = snapToStep(rawValue);

    newValue = Math.max(0, Math.min(max, newValue)); // Clamp within 0 and max
    onChange(newValue);
  };

  const position = progressWidth ? (progressWidth * value) / max : 0;

  return (
    <div className={cn("progress-bar")} ref={bar} onClick={onClick}>
      <div className={cn("progress-bar-meter")} style={{ width: `${(value / max) * 100}%` }} />
      <div className={cn("toggler-wrapper")}>
        <Draggable
          axis="x"
          onDrag={handleChange}
          bounds="parent"
          position={{ x: position, y: 0 }}
        >
          <div className={cn("toggler")}>
            <div className={cn("toggler-btn")}>
              <div className={cn("toggler-content")} />
              <div className={cn("toggler-content")} />
              <div className={cn("toggler-content")} />
              <div className={cn("toggler-tooltip")}>{value}{label && <>&nbsp;{label}</>}</div>
            </div>
          </div>
        </Draggable>
      </div>
    </div>
  );
};

RangeInput.propTypes = {
  value: T.number.isRequired,
  onChange: T.func.isRequired,
  step: T.number,
  max: T.number,
  label: T.oneOfType([T.string, T.object]),
};

RangeInput.defaultProps = {
  step: 1,
  max: 100,
};

export default RangeInput;
