import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { reportFeedOrder } from 'reducers/dailyCheckup/checkupEdit';
// components
import CheckupSegment from './CheckupSegment/CheckupSegment';
import { FormattedMessage } from 'react-intl';
// utils
import { isSomeSectionReporting } from 'utils/checkupHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import SegmentComment from './CheckupSegmentComment/SegmentComment';
import { getCommentByRole } from 'helpers.js';
import classnames from 'classnames/bind';
import { convertLbsToTons } from "utils/weightHelper";
import { formatNumber } from "utils";
// styles
import styles from './OrderFeedSegment.module.scss';
// constants
import { comment_roles } from 'constants.js';

const { FEED_ORDER_ROLE } = comment_roles;
const cn = classnames.bind(styles);

const OrderFeedSegment = ({
  checkup,
  paramsCheckupId,
  isSubmitting,
  isDisabled,
  validateCheckup,
  reportFeedOrder,
}) => {
  const [isAllGoodReported, setIsAllGoodReported] = useState(false);
  const feedOrderBins = checkup.feed_order_bins || [];
  const isReported = checkup.feed_order_bin_count !== null;
  const { comments } = checkup;
  const bins_count = feedOrderBins.length;

  const totalQuantity = feedOrderBins.reduce((acc, bin) => {
    const quantity = parseFloat(bin.quantity);
    return acc + (Number.isNaN(quantity) ? 0 : quantity);
  }, 0);

  const handleReportNoFeed = () => {
    if (!validateCheckup(checkup)) return;
    setIsAllGoodReported(true);
    reportFeedOrder(paramsCheckupId, { all_good: true }).catch(toastResponseErrors);
  };

  return (
    <CheckupSegment
      isSegmentConditionChanged={isAllGoodReported}
      isSubmitting={isSubmitting}
      isDisabled={isDisabled}
      isReported={isReported}
      itemsCount={bins_count}
      onEmptyDataSubmit={handleReportNoFeed}
      titleKey="component.checkupSegment.doYouNeedOrderFeed"
      titleKeyWhenReported="general.orderFeed"
      reportPageUrl={`/daily-checkup/${paramsCheckupId}/order-feed`}
      noData={isReported && !bins_count}
    >
      <div className={cn('reported-feed-order')}>
        {bins_count > 0 && (
          <div className={cn('feed-order-row')}>
            <div className={cn('name')}>
              <strong><FormattedMessage id="general.totalOrder" /></strong>
            </div>
            <div className={cn('value')}>
              <span>
                <strong>
                  {formatNumber(totalQuantity)} <FormattedMessage id="general.weight.lbs" />
                </strong>
                <small>
                  &nbsp;(<FormattedMessage
                    id="general.tonsValue"
                    values={{ value: formatNumber(convertLbsToTons(totalQuantity)) }}
                  />)
                </small>
              </span>
            </div>
          </div>
        )}
        <SegmentComment comment={getCommentByRole(comments, FEED_ORDER_ROLE)} />
      </div>
    </CheckupSegment>
  );
};

OrderFeedSegment.propTypes = {
  checkup: T.object.isRequired,
  paramsCheckupId: T.oneOfType([T.string, T.number]).isRequired,
  reportPigs: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  isDisabled: T.bool.isRequired,
  isSubmitting: T.bool.isRequired,
  reportFeedOrder: T.func.isRequired,
};

export default connect(
  (state) => ({
    isSubmitting: state.dailyCheckup.checkupEdit.isReportingFeedOrder,
    isDisabled:
      isSomeSectionReporting(state.dailyCheckup.checkupEdit) &&
      !state.dailyCheckup.checkupEdit.isReportingFeedOrder,
  }),
  {
    reportFeedOrder,
  }
)(OrderFeedSegment);
