import React from 'react';
import { FormattedMessage } from 'react-intl';
import range from 'lodash.range';

export const API_URL = process.env.REACT_APP_API_URL || '/api';
export const FRONTEND_V2_URL = process.env.REACT_APP_EP_FRONTEND_V2_URL;
export const GA_CODE = process.env.REACT_APP_GA_CODE;
export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const APP_VERSION = process.env.BUILD_VERSION;

export const METERS_IN_MILE = 1609;

export const MAIN_HEADER_HEIGHT = 55;

export const MAX_PDF_SCALE = 2.5;
export const MIN_PDF_SCALE = 0.5;

export const DIAGNOSE_PANEL_WIDTH = 350;

export const rangeOptions = ['today', 'yesterday', '2_days', '7_days', '14_days', '4_last_weeks', 'last_3_months',
  'quarter_to_date', 'last_12_months', 'this_year'];

export const deathTypes = [
  { label: <FormattedMessage id="general.deaths.acute" />, key: 'acute' },
  { label: <FormattedMessage id="general.deaths.chronic" />, key: 'chronic' },
  { label: <FormattedMessage id="general.deaths.euthanasia" />, key: 'euthanasia' },
  { label: <FormattedMessage id="general.treated" />, key: 'treated' },
];

export const farmTypes = [
  { label: <FormattedMessage id="general.nursery" />, value: 'n' },
  { label: <FormattedMessage id="general.finisher" />, value: 'f' },
  { label: <FormattedMessage id="general.weanToFinish" />, value: 'wf' },
  { label: <FormattedMessage id="general.farrowToFinish" />, value: 'ff' },
  { label: <FormattedMessage id="general.mixed" />, value: 'm' },
  { label: <FormattedMessage id="general.sowUnit" />, value: 'su' },
];

export const loadTypes = [
  { label: <FormattedMessage id="general.loadType.sale" />, value: 'sale' },
  { label: <FormattedMessage id="general.loadType.arrival" />, value: 'arrival' },
  { label: <FormattedMessage id="general.loadType.transfer" />, value: 'transfer' },
];

export const commitmentStatuses = [
  { label: <FormattedMessage id="general.status.pending" />, value: 'pending' },
  { label: <FormattedMessage id="general.status.accepted" />, value: 'accepted' },
  { label: <FormattedMessage id="general.status.declined" />, value: 'declined' },
];

export const loadingStatuses = [
  { label: <FormattedMessage id="general.status.pending" />, value: 'pending' },
  { label: <FormattedMessage id="general.status.confirmed" />, value: 'confirmed' },
  { label: <FormattedMessage id="general.status.in_progress" />, value: 'in_progress' },
  { label: <FormattedMessage id="general.status.conflict" />, value: 'conflict' },
  { label: <FormattedMessage id="general.status.incomplete" />, value: 'incomplete' },
];

export const sourceTypes = [
  ...farmTypes,
  { label: <FormattedMessage id="general.sourceTypes.commingled" />, value: 'co' },
];

export const destinationTypes = farmTypes
  .concat([{ label: <FormattedMessage id="general.destinationTypes.packingPlant" />, value: 'pp' }])
  .sort((a) => Number(a.value === 'm'));

export const antibioticStatsOptions = [
  { name: <FormattedMessage id="component.statsOptions.yesterday" />, value: 'yesterday' },
  { name: <FormattedMessage id="component.statsOptions.weekToDate" />, value: 'week_to_date' },
  { name: <FormattedMessage id="component.statsOptions.lastWeek" />, value: 'last_week' },
  { name: <FormattedMessage id="component.statsOptions.monthToDate" />, value: 'month_to_date' },
  { name: <FormattedMessage id="component.statsOptions.lastMonth" />, value: 'last_month' },
  { name: <FormattedMessage id="component.statsOptions.last30Days" />, value: 'last_30_days' },
  { name: <FormattedMessage id="component.statsOptions.yearToDate" />, value: 'year_to_date' },
  { name: <FormattedMessage id="component.statsOptions.lastYear" />, value: 'last_year' },
  { name: <FormattedMessage id="component.statsOptions.lastQuarter" />, value: 'last_quarter' },
  { name: <FormattedMessage id="component.statsOptions.quarterToDate" />, value: 'quarter_to_date' },
  { name: <FormattedMessage id="component.statsOptions.custom" />, value: 'custom' },
];

export const antibioticFarmTypesFilters = [
  { value: '', label: <FormattedMessage id="general.system" /> },
  ...farmTypes,
];

export const languageMapping = {
  en: 'en',
  'es-CO': 'es',
};

export const tenantExpirationOptions = [1, 5, 10, 15, 30, 45, 60].map((value) => ({
  value,
  label: value + `${value > 1 ? ' Days' : ' Day'}`,
}));

export const requiredTenantFields = ['name', 'address', 'city', 'state', 'zipcode', 'country_code', 'language',
  'shareholder_group_on', 'ai_img_prediction', 'ai_mortality_prediction', 'ai_treatment_prediction',
  'ai_diagnosis_prediction', 'logistics', 'admin_svrs', 'virtual_visits', 'admin_treatment_protocols',
  'everypig_basic'];

export const tenantSettingsCheckboxes = [
  {
    name: 'everypig_basic', text: 'EveryPig Basic',
    description: <FormattedMessage id="component.tenantForm.everypigbasic.description" />,
  },
  {
    name: 'shareholder_group_on', text: 'Shareholder Groups',
    description: <FormattedMessage id="component.tenantForm.shareholderGroups.description" />
  },
  {
    name: 'ai_mortality_prediction', text: 'Mortality predictions',
    description: <FormattedMessage id="component.tenantForm.mortalityPrediction.desc" />
  },
  {
    name: 'ai_treatment_prediction', text: 'Treatment recommendations',
    description: <FormattedMessage id="component.tenantForm.treatmentPrediction.desc" />
  },
  {
    name: 'ai_img_prediction', text: 'Image recognition',
    description: <FormattedMessage id="component.tenantForm.aiImgPrediction.description" />
  },
  {
    name: 'ai_diagnosis_prediction', text: 'Diagnosis forecast',
    description: <FormattedMessage id="component.tenantForm.diagnosisPrediction.desc" />
  },
  {
    name: 'logistics', text: 'Logistics',
    description: <FormattedMessage id="component.tenantForm.logistics.description" />
  },
  {
    name: 'admin_svrs', text: 'Reports',
    description: <FormattedMessage id="general.reports.desc" />
  },
  {
    name: 'virtual_visits', text: 'Virtual Visits',
    description: <FormattedMessage id="component.tenantForm.virtualVisits.desc" />
  },
  {
    name: 'admin_treatment_protocols', text: 'Treatment Protocols',
    description: <FormattedMessage id="component.tenantForm.treatmentProtocols.desc" />
  },
];

export const comment_roles = {
  CHECKUP_ROLE: 'checkup',
  PIG_MIGRATIONS_ROLE: 'pig_migrations',
  DEATHS_ROLE: 'deaths',
  TREATMENTS_ROLE: 'treatments',
  SYMPTOMS_ROLE: 'symptoms',
  TEMPERATURE_ROLE: 'temperature',
  WATER_USAGE_ROLE: 'water_usage',
  FEED_ORDER_ROLE: 'feed_order',
};

export const inventoryReconciliationOptions = [
  { value: 'mon', label: <FormattedMessage id="general.day.mondaySunday" /> },
  { value: 'tue', label: <FormattedMessage id="general.day.tuesdayMonday" /> },
  { value: 'wed', label: <FormattedMessage id="general.day.wednesdayTuesday" /> },
  { value: 'thu', label: <FormattedMessage id="general.day.thursdayWednesday" /> },
  { value: 'fri', label: <FormattedMessage id="general.day.fridayThursday" /> },
  { value: 'sat', label: <FormattedMessage id="general.day.saturdayFriday" /> },
  { value: 'sun', label: <FormattedMessage id="general.day.sundaySaturday" /> },
];

export const withdrawalWeightOptions = [
  { value: 'lb', label: <FormattedMessage id="general.pounds" /> },
  { value: 'kg', label: <FormattedMessage id="general.kg" /> },
];

export const autoReportPeriodOptions = [
  { value: 'daily', label: <FormattedMessage id="general.timeOptions.daily" /> },
  { value: 'weekly', label: <FormattedMessage id="general.timeOptions.weekly" /> },
  { value: 'monthly', label: <FormattedMessage id="general.timeOptions.monthly" /> },
];

export const companyTenantRoles = [
  {
    value: 'tenant_admin',
    label: <FormattedMessage id="general.role.admin" />,
    description: <FormattedMessage id="component.createAdminForm.adminDesc" />,
  },
  {
    value: 'tenant_owner',
    label: <FormattedMessage id="general.role.tenantOwner" />,
    description: <FormattedMessage id="component.createAdminForm.ownerDesc" />,
  },
];

export const sourceRangeOptions = [
  { label: <FormattedMessage id="general.timeOptions.7days" />, value: '7_days' },
  { label: <FormattedMessage id="general.timeOptions.14days" />, value: '2_weeks' },
  { label: <FormattedMessage id="general.timeOptions.1month" />, value: '1_months' },
  { label: <FormattedMessage id="general.timeOptions.3months" />, value: '3_months' },
  { label: <FormattedMessage id="general.timeOptions.6months" />, value: '6_months' },
  { label: <FormattedMessage id="general.timeOptions.12months" />, value: '12_months' },
];

export const customUserFieldsErrors = [
  { key: 'email', error: <FormattedMessage id="general.customErrors.email" /> },
  { key: 'nickname', error: <FormattedMessage id="general.customErrors.username" /> },
];

export const addressFields = ['address', 'city', 'state', 'country_code', 'zipcode', 'lat', 'lng'];

export const companyGroupsTabFilters = [
  { label: <FormattedMessage id="general.allGroups" />, value: '' },
  { label: <FormattedMessage id="general.status.action_required" />, value: 'pending_close' },
  { label: <FormattedMessage id="general.status.pending" />, value: ['awaiting_delivery', 'scheduled_delivery'] },
  { label: <FormattedMessage id="general.status.opened" />, value: ['opened', 'request_close'] },
  { label: <FormattedMessage id="general.status.closed" values={{ date: null }} />, value: 'closed' },
];

export const utcOffsets = range(-12, 15).map((value) => ({
  value,
  label: (value === 0) ? 'UTC' : (`UTC${value > 0 ? '+' : ''}${value}:00`)
}));

export const hours = range(0, 24).map((value) => ({
  value,
  label: (value < 10) ? `0${value}:00` : `${value}:00`
}));
