import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux, actions, recompose
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
// component
import SearchBox from "components/SearchBox";
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import NothingBox from 'components/NothingBox';
import { CustomColumn } from 'components/DataTable/Columns';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// hooks
import useDataTableController from 'hooks/useDataTableController';
// assets
import './FarmBins.scss';

const labels = {
  dataTable: {
    name: <FormattedMessage id="component.dataTable.headers.binID" />,
    capacity: <FormattedMessage id="component.dataTable.headers.capacity" />,
  },
  bins: <FormattedMessage id="general.bins" />,
};

const FarmBins = ({
  fetchData,
  isLoading,
  meta,
  params = {},
  resources,
  reqParams,
}) => {
  const [isBarnUpdating, setIsBarnUpdating] = useState(false);
  const { onPageChange, onPerPageChange, onSearchChange, onSortChange } = useDataTableController(fetchData, params);
  const { search, sort, page, per_page } = reqParams;

  const paginationProps = {
    totalItems: meta.total,
    currentPage: page,
    perPage: per_page,
    onPageChange,
    onPerPageChange,
  };

  useEffect(() => {
    fetchData(reqParams).catch(toastResponseErrors);
  }, []);

  const renderBinIdColumn = ({ name }) => {
    return (
      <CustomColumn flexRow>
        {name}
      </CustomColumn>
    );
  };

  const renderUserRoleColumn = ({ capacity }) => (
    <CustomColumn flexRow label={labels.dataTable.capacity}>
      {capacity || '-'}
    </CustomColumn>
  );

  const columns = [
    { label: labels.dataTable.name, flex: '1 0 250px', renderer: renderBinIdColumn, sortKey: 'name' },
    { label: labels.dataTable.capacity, flex: '1 0 100px', renderer: renderUserRoleColumn, sortKey: 'capacity' },
  ];

  return (
    <Panel className="FarmBins">
      <Panel.Heading title={labels.bins}>
        <SearchBox initialValue={search} onChange={onSearchChange} />
      </Panel.Heading>
      <Panel.Body noPadding>
        <Preloader isActive={isLoading || isBarnUpdating} />
        <DataTable
          paginationProps={paginationProps}
          onSortChange={onSortChange}
          data={resources}
          columns={columns}
          sort={sort}
          scrollable
          tableKey="farmBins"
        />
        <NothingBox itemsName="bins" display={!resources.length} isLoading={isLoading}>
          <FormattedMessage tagName="h1" id="component.nothingBox.farmHasNoBins" />
        </NothingBox>
      </Panel.Body>
    </Panel>
  );
};

FarmBins.propTypes = {
  resources: T.array.isRequired,
  fetchData: T.func.isRequired,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  params: T.object,
  meta: T.object,
};

export default connect(
  (state) => ({
    resources: state.dataTable.farmBins.resources,
    reqParams: state.dataTable.farmBins.params,
    isLoading: state.dataTable.farmBins.isLoading,
    meta: state.dataTable.farmBins.meta,
  }), (dispatch, { params: { id } }) => ({
    fetchData: (query) => dispatch(fetchData(`/admin/farms/${id}/bins`, 'farmBins', query)),
  }),
)(FarmBins);
