import React from 'react';
import getValue from 'lodash.get';
import { FormattedMessage } from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
import moment from 'moment';
import { getCommitmentDirection } from 'utils/commitmentsHelper';

const formatNotificationDate = (someDate, format = 'MM/DD/YYYY') => {
  if (!someDate) return '';
  let dateText = moment(someDate).from(new Date());
  const startOfToday = moment().startOf('day');
  const startOfDate = moment(someDate).startOf('day');
  const daysDiff = startOfDate.diff(startOfToday, 'days');
  const days = {
    '0': 'today',
    '-1': 'yesterday',
    '1': 'tomorrow',
  };

  if (Math.abs(daysDiff) <= 1) {
    dateText = days[daysDiff];
  } else {
    dateText = moment(someDate).format(format);
  }

  return dateText;
};

export function getAudioPath(type) {
  switch (type) {
    case 'ff_comment':
    case 'mention':
      return '/static/sounds/comment_sound.mp3';
    case 'ai_suggestion':
      return '/static/sounds/ai_notification_sound.wav';
    default:
      return '/static/sounds/notification_sound.mp3';
  }
}

const getLogisticsNotificationValues = (type, target) => {
  const dateTimeFormat = 'MM/DD/YYYY LT';
  const dateFormat = 'MM/DD/YYYY';
  const timeFormat = 'hh:mm A';
  const commonValues = {
    loading_name: getValue(target, 'loading.name', ''),
    farm_name: getValue(target, 'farm.name', ''),
    barn_name: getValue(target, 'source_barn.name', null),
    group_name: getValue(target, 'pig_group.name', null),
  };

  switch (type) {
    case 'sales_result_created_production':
      return {
        ...commonValues,
        pick_up_date: moment(getValue(target, 'source_loading_commitment.arrive_at')).format(dateFormat),
        pick_up_time: moment(getValue(target, 'source_loading_commitment.arrive_at')).format(timeFormat),
        source_group_name: getValue(target, 'source_loading_commitment.pig_group.name', null),
        source_farm_name: getValue(target, 'source_loading_commitment.farm.name', null),
      };
    case 'loading_pig_group_expected':
      return commonValues;
    case 'loading_created_production':
      return {
        ...commonValues,
        arrive_date: moment(getValue(target, 'arrive_at')).format(dateFormat),
        arrive_time: moment(getValue(target, 'arrive_at')).format(timeFormat),
        loading_date_time: moment(getValue(target, 'arrive_at')).format(dateTimeFormat),
      };
    case 'loading_force_accepted_production':
      return {
        ...commonValues,
        arrive_date: moment(getValue(target, 'arrive_at')).format(dateFormat),
        arrive_time: moment(getValue(target, 'arrive_at')).format(timeFormat),
        loading_date_time: moment(getValue(target, 'arrive_at')).format(dateTimeFormat),
      };
    case 'loading_canceled_production':
      return {
        ...commonValues,
        loading_date_time: moment(getValue(target, 'arrive_at')).format(dateTimeFormat),
      };
    case 'loading_edited_production':
    case 'loading_edited_confirmation_production':
      return {
        loading_name: getValue(target, 'loading.name', ''),
      };
    default: return {};
  }
};

export const getNotificationCaption = (type, target, initiator, options, user, isInAppNotif) => {
  const directPostFarmname = getValue(target, 'farm', null)
    ? getValue(target, 'farm.name', '')
    : getValue(target, 'company.name', '');

  const username = (getValue(initiator, 'id', null) === getValue(user, 'id', undefined))
    ? <FormattedMessage id="general.you" />
    : getValue(initiator, 'full_name', 'unknown');

  const values = {
    b: (text) => <b>{text}</b>,
    username: <span className="color-primary">{username}</span>,
    diagnosisGroupname: <b>{getValue(target, 'pig_group.name', '')}</b>,
    directPostFarmname: directPostFarmname ? <b>{directPostFarmname}</b> : '',
    diagnosisFarmname: <b>{getValue(target, 'farm.name', '')}</b>,
    groupname: <b>{getValue(target, 'name', '')}</b>,
    farmname: getValue(target, 'farm.name', '') ? <b>{target.farm.name}</b> : null,
    template_name: getValue(target, 'name', '') ? <b>{target.name}</b> : null,
    groupDate: getValue(target, 'delivery_start', '') ? <b>{target.delivery_start}</b> : null,
    date: <b>{formatNotificationDate(getValue(target, 'created_at', ''))}</b>,
    farmOrGroup: getValue(target, 'farm', null)
      ? <b>{getValue(target, 'farm.name', '')}</b>
      : <b>{getValue(target, 'pig_group.name', '')}</b>,
    old_groupname: <b>{getValue(options, 'old_pig_group_name', '')}</b>,
    treatment_protocol_name: getValue(target, 'name'),
    treatment_protocol_group_name: getValue(target, 'pig_group.name'),
    ...getLogisticsNotificationValues(type, target),
  };
  const directPostNotForUser = type === 'direct_post' && values.directPostFarmname;
  if (type === 'svr_mention') return <FormattedMessage id="component.notifications.svr_mention" values={values} />;
  if (directPostNotForUser) return <FormattedMessage id="component.notifications.mention" values={values} />;
  const mentionWithoutFarm = type === 'mention' && !values.directPostFarmname;
  if (mentionWithoutFarm) return <FormattedMessage id="component.notifications.direct_post" values={values} />;

  if (type === 'ai_suggestion') {
    return (
      <FormattedMessage
        id="component.toastr.suggestions.text"
        values={{
          count: options.suggestionsCount,
          b: (msg) => <b>{msg}</b>
        }}
      />
    );
  }

  if (type === 'dc_mention') {
    return (
      <FormattedMessage
        id="component.notifications.dc_mention"
        values={{ groupname: <b>{target.pig_group.name}</b> }}
      />
    );
  }

  if (type === 'tenant_resource') {
    return (
      <FormattedMessage
        id={`component.notifications.tenant_${getValue(target, 'link') ? 'weblink' : 'file'}`}
        values={{
          username: <span className="color-primary">{initiator.full_name}</span>,
          tenantName: user.current_company.name,
        }}
      />
    );
  }

  if (type === 'open_group') {
    return <FormattedMessage id="component.notifications.open_group_with_date" values={values} />;
  }

  if (type === 'loading_pig_group_created') {
    return (
      <FormattedMessage
        id={`component.notifications.${type}`}
        values={{
          b: (msg) => <b>{msg}</b>,
          pig_group_name: getValue(target, 'name'),
          loading_name: getValue(options, 'loading_name')
        }}
      />
    );
  }

  if (type === 'birthday_event' && user.id === initiator.id) {
    return (
      <FormattedMessage
        id="component.notifications.self_birthday_event"
        values={{
          username: <span className="color-primary">{initiator.full_name}</span>,
        }}
      />
    );
  }

  if (type === 'lowest_mortality_event') {
    return (
      <span>
        <FormattedMessage id="general.congratulations" values={{ first_name: <b>{user.first_name}</b> }} />
        &nbsp;
        <FormattedMessage
          id="component.notifications.mortality_encouragement"
          values={{
            b: (msg) => (<b>{msg}</b>),
            group_name: getValue(target, 'pig_group.name', null),
            farm_name: target.farm.name,
            farm_type: getValue(target, 'farm.farm_type', null),
            tenant_name: getValue(user, 'current_company.name', null)
          }}
        />
      </span>
    );
  }

  if (type === 'withdrawal_warning_event') {
    return (
      <FormattedMessage
        id={`component.notifications.${type}`}
        values={{
          b: (msg) => <b>{msg}</b>,
          yellow: (msg) => <b className="color-brand-orange">{msg}</b>,
          group_name: getValue(target, 'pig_group.name', null),
          farm_name: target.farm.name,
          weight: <FormattedWeight value={getValue(target, 'additional_data.pg_estimated_weight')} />,
          treatmentsList: getValue(target, 'additional_data.withdrawal_data', []).map(({ name }) => name).join(', '),
        }}
      />
    );
  }

  if (type === 'group_import') {
    return (
      getValue(target, 'action', '') === 'renaming'
        ? (
          <FormattedMessage
            id="component.notifications.group_import_rename"
            values={{
              renamedCount: getValue(target, 'result.renamed_count', 0),
              errorsCount: getValue(target, 'result.invalid_rows', []).length,
            }}
          />
        )
        : (
          <FormattedMessage
            id="component.notifications.group_import"
            values={{
              createdCount: getValue(target, 'result.created_count', 0),
              errorsCount: getValue(target, 'result.invalid_rows', []).length,
            }}
          />
        )
    );
  }

  if (type === 'diagnosis_encouragement') {
    return (
      <FormattedMessage
        id="component.notifications.diagnosis_encouragement"
        values={{
          lastname: <b>{initiator.last_name || 'unknown'}</b>,
          diagnosis: <FormattedMessage
            id="general.submittedDiagnosisSum"
            tagName="b"
            values={{ value: target.additional_data.milestone }}
          />,
        }}
      />
    );
  }

  if (type === 'media_encouragement') {
    const type = isInAppNotif ? 'media_encouragement' : 'media_encouragement_long';
    return (
      <FormattedMessage
        id={`component.notifications.${type}`}
        values={{
          firstname: <b>{initiator.first_name || 'unknown'}</b>,
          media: <FormattedMessage
            id="general.submittedMediaSum"
            tagName="b"
            values={{ value: target.additional_data.milestone }}
          />,
        }}
      />
    );
  }
  if (type === 'water_usage_warning_event') {
    return (
      <FormattedMessage
        id={`component.notifications.${type}`}
        values={{
          b: (msg) => <b>{msg}</b>,
          yellow: (msg) => <b className="color-brand-orange">{msg}</b>,
          group_name: getValue(target, 'pig_group.name', null),
          farm_name: target.farm.name,
          water_usage_diff_percentage: target.additional_data.water_usage_diff_percentage
        }}
      />
    );
  }
  if (type === 'general_water_usage_warning_event') {
    return (
      <FormattedMessage
        id={`component.notifications.${type}`}
        values={{
          b: (msg) => <b>{msg}</b>,
          yellow: (msg) => <b className="color-brand-orange">{msg}</b>,
        }}
      />
    );
  }
  if (type === 'general_withdrawal_warning_event') {
    return (
      <FormattedMessage
        id={`component.notifications.${type}`}
        values={{
          b: (msg) => <b>{msg}</b>,
          yellow: (msg) => <b className="color-brand-orange">{msg}</b>,
        }}
      />
    );
  }
  if (type === 'feed_order_submitted') {
    return (
      <FormattedMessage
        id={`component.notifications.${type}`}
        values={{
          group_name: <b>{getValue(target, 'pig_group.name', null)}</b>,
          farm_name: <b>{target.farm.name}</b>,
          username: <span className="color-primary">{initiator.full_name}</span>,
        }}
      />
    );
  }
  if (type === 'api_sync') {
    return (
      <FormattedMessage
        id="container.myProfile.settings.notification.monitoring"
      />
    );
  }

  if ([
    'loading_created_production',
    'loading_force_accepted_production'
  ].includes(type)) {
    return (
      <FormattedMessage
        id={`component.notifications.${type}.${getCommitmentDirection(target)}`}
        values={values}
      />
    );
  }

  if (['loading_edited_production', 'loading_edited_confirmation_production'].includes(type)) {
    return (
      <FormattedMessage
        id="component.notifications.loading_edited_production"
        values={values}
      />
    );
  }

  if (type === 'out_of_compliance_groups_alert') {
    return (
      <FormattedMessage
        id={`component.notifications.${type}`}
        values={{
          b: (msg) => <b>{msg}</b>,
          yellow: (msg) => <b className="color-brand-orange">{msg}</b>,
          groups_count: options?.groups_count || 0
        }}
      />
    );
  }

  return <FormattedMessage id={`component.notifications.${type}`} values={values} />;
};

export const getRespondUrl = (type, target) => {
  const getCommentUrl = () => {
    const id = getValue(target, 'activity_id') || getValue(target, 'id');
    return id ? `/farmfeed/activities/${id}` : '';
  };

  const getMentionUrl = () => {
    return getValue(target, 'id') ? `/farmfeed/activities/${getValue(target, 'id')}` : '';
  };

  const getSvrMentionURL = () => {
    const farmId = getValue(target, 'farm.id');
    const svrId = getValue(target, 'svr_id');
    const templateId = getValue(target, 'svr_template_id');
    const commentId = getValue(target, 'id');

    return svrId ?
      `/report-farms/${farmId}/report-templates/${templateId}/reports/${svrId}/review?mentionCommentId=${commentId}` :
      '';
  };

  const getPigGroupTreatmentProtocolURL = () => {
    const farmId = getValue(target, 'pig_group.farm_id');
    const groupId = getValue(target, 'pig_group.id');
    const treatmentProtocolId = getValue(target, 'id');

    return `/treatment-protocols-farms/${farmId}/groups/${groupId}/treatment-protocols/${treatmentProtocolId}`;
  };

  switch (type) {
    case 'request_close_group':
      return '/groups?status=action_required';
    case 'change_pig_group_name':
      return `/daily-checkup/farms/${getValue(target, 'farm.id')}`;
    case 'loading_pig_group_created':
      return getValue(target, 'id') ? `/groups/create?draftId=${getValue(target, 'id')}` : '';
    case 'open_group':
      return getValue(target, 'id') ? `/groups/${getValue(target, 'id')}` : '';
    case 'group_import':
    case 'loading_pig_group_expected':
      return '/groups';
    case 'farm_compliance':
      return '/daily-checkup';
    case 'health_alert':
    case 'dc_mention':
    case 'diagnosis_alert':
      return getValue(target, 'id') ? `/barnsheets/daily-checkup/${getValue(target, 'id')}` : '';
    case 'water_usage_warning_event':
    case 'general_water_usage_warning_event':
    case 'general_withdrawal_warning_event':
      return getValue(target, 'id') ? `/farmfeed/activities/${getValue(target, 'id')}` : '';
    case 'mention':
    case 'direct_post':
      return getValue(target, 'comment') ? getCommentUrl() : getMentionUrl();
    case 'svr_mention':
      return getSvrMentionURL();
    case 'svr_note_mention':
      return target?.farm?.id && target?.svr_id && target?.svr_template_id ?
        `/report-farms/${target.farm.id}/report-templates/${target.svr_template_id}/reports/${target.svr_id}/review` :
        '';
    case 'birthday_event':
    case 'diagnosis_encouragement':
    case 'media_encouragement':
    case 'ff_comment':
    case 'lowest_mortality_event':
      return getCommentUrl();
    case 'tenant_resource':
      return '/tenant-assets';
    case 'sales_result_created_production':
      return getValue(target, 'id') ?
        `/my-schedule/${getValue(target, 'source_loading_commitment.id')}/sales-results` :
        '';
    case 'loading_created_production':
    case 'loading_edited_production':
    case 'loading_edited_confirmation_production':
    case 'loading_force_accepted_production':
      return getValue(target, 'id') ? `/my-schedule/${getValue(target, 'id')}` : '';
    case 'svr_created_alert':
    case 'svr_question_fixed_alert':
      return `/report-farms/${target.farm.id}/report-templates/${target.svr_template_id}/reports/${target.id}/review`;
    case 'svr_compliance':
      return '/report-farms';
    case 'api_sync':
      return '/monitoring';
    case 'pg_treatment_protocol_created':
    case 'pg_treatment_protocol_edited':
    case 'pg_treatment_protocol_step_missed':
      return getPigGroupTreatmentProtocolURL();
    case 'out_of_compliance_groups_alert':
      return '/treatment-protocols-groups';
    default: return '';
  }
};
