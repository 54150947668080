import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
// components
import Preloader from 'components/Preloader';
import { CustomColumn } from 'components/DataTable/Columns';
import DataTable from 'components/DataTable/DataTable';
import { FormattedMessage } from 'react-intl';
import Panel from 'components/Panel';
import NothingBox from 'components/NothingBox';
// utils
import moment from 'moment';
import { tableNames } from 'utils/constants';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// endpoints
import { getPigGroupBins } from 'endpoints/pigGroups';

const fetchGroupFeedOrders = (groupId, params) => {
  return getPigGroupBins(groupId, params);
};

const BarnSheetsGroupFeedOrders = ({
  tableParams,
  setTableParams,
  params: { id }
}) => {
  const {
    onPageChange,
    onPerPageChange,
    onSortChange,
    fetchData,
    data,
    data: { resources, isLoading, meta: { total } },
  } = useDataTable((params) => fetchGroupFeedOrders(id, params), {
    setTableParams: (params) => setTableParams(tableNames.barnSheetsGroupFeedOrders, params),
    tableParams,
  });

  console.log(data);

  useEffect(() => {
    fetchData(tableParams);
  }, []);

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: parseInt(total, 10),
    currentPage: parseInt(tableParams.page, 10),
    perPage: parseInt(tableParams.per_page, 10),
  };

  const renderOrderBinNameColumn = (data) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.binID" />}>
      {data.bin_names.length ? data.bin_names.join(', ')  : <FormattedMessage id="general.notAvail" />}
    </CustomColumn>
  );

  const renderOrderDeliveryDateColumn = (data) => (
    <CustomColumn label={<FormattedMessage id="general.date" />}>
      {data.created_on ? moment(data.created_on).format('MM/DD/YYYY') : <FormattedMessage id="general.notAvail" />}
    </CustomColumn>
  );

  const renderOrderQuantityColumn = (data) => (
    <CustomColumn label={<FormattedMessage id="general.orderQuantity" />}>
      {data.quantity ? (
        <span>
          {data.quantity} <FormattedMessage id="general.weight.lbs" />
        </span>
      ) : (
        <FormattedMessage id="general.notAvail" />
      )}
    </CustomColumn>
  );

  const renderOrderCommentColumn = (data) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="general.comment" />}>
      {data.comment || <FormattedMessage id="general.notAvail" />}
    </CustomColumn>
  );

  const columns = [
    {
      label: <FormattedMessage id="general.date" />,
      flex: '1 1 140px',
      renderer: renderOrderDeliveryDateColumn,
      sortKey: 'created_on'
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.binID" />,
      flex: '1 1 140px',
      renderer: renderOrderBinNameColumn,
      sortKey: 'bin_names_array',
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.orderQuantity" />,
      flex: '1 1 140px',
      renderer: renderOrderQuantityColumn,
      sortKey: 'total_quantity'
    },
    {
      label: <FormattedMessage id="general.comment" />,
      flex: '1 1 140px',
      renderer: renderOrderCommentColumn,
    },
  ];

  return (
    <Panel>
      <Panel.Heading className="farms-panel-title" title={<FormattedMessage id="general.feedOrders" />}>
      </Panel.Heading>
      <Panel.Body noPadding>
        <Preloader isActive={isLoading} />
        <DataTable
          sort={tableParams.sort}
          data={resources}
          columns={columns}
          paginationProps={paginationProps}
          isLoading={isLoading}
          onSortChange={onSortChange}
          scrollable
        />

        <NothingBox display={!resources.length} isLoading={isLoading}>
          <FormattedMessage tagName="h2" id="component.nothingBox.noData" />
        </NothingBox>
      </Panel.Body>
    </Panel>
  );
};

BarnSheetsGroupFeedOrders.propTypes = {
  tableParams: T.object.isRequired,
  params: T.object.isRequired,
  setTableParams: T.func.isRequired,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.barnSheetsGroupFeedOrders),
  }), {
    setTableParams,
  }
)(BarnSheetsGroupFeedOrders);
