import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import Link from 'react-router/lib/Link';
import Button from 'components/Button';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import Translation from 'components/Translation';
import LogisticsNotificationIcon from 'components/Notifications/LogisticsNotificationIcon';
// utils
import cn from 'classnames';
import isEmpty from 'lodash.isempty';
import { isMobile } from 'react-device-detect';
import { getNotificationCaption, getRespondUrl } from 'utils/notificationsHelper';
import { renderEncourageIcon, renderMentionsText } from 'utils';
import { diagnosesTypes } from 'utils/diagnosesHelper';
import { getCommentByRole } from 'helpers';
// styles
import './NotificationItemBody.scss';
// assets
import congratIcon from '../../../../public/images/congrat-icon.png';
import trophyIcon from '../../../../public/images/trophy-icon.png';
import cloudUploadIcon from '../../../../public/images/cloud-upload.svg';

const typesWithAvatars = ['ff_comment', 'direct_post', 'mention', 'svr_mention', 'diagnosis_alert', 'open_group',
  'shared_post', 'request_close_group', 'health_alert', 'birthday_event', 'tenant_resource',
  'loading_force_accepted_production'];

const iconForType = {
  group_import: 'fa fa-ep-groups',
  loading_pig_group_created: 'fa fa-ep-groups',
  change_pig_group_name: 'fa fa-ep-groups',
  svr_compliance: 'fa fa-clock',
  farm_compliance: 'fa fa-clock',
  health_alert: 'fa fa-warning',
  feed_order_submitted: 'fa fa-warning',
  loading_pig_group_expected: 'fa fa-warning',
  withdrawal_warning_event: 'fa fa-exclamation-triangle',
  water_usage_warning_event: 'fa fa-exclamation-triangle',
  general_water_usage_warning_event: 'fa fa-exclamation-triangle',
  general_withdrawal_warning_event: 'fa fa-exclamation-triangle',
  svr_created_alert: 'fa fa-site-visit-report',
  svr_question_fixed_alert: 'fa fa-site-visit-report',
  pg_treatment_protocol_created: 'fa fa-ep-groups',
  out_of_compliance_groups_alert: 'fa fa-ep-groups',
  pg_treatment_protocol_edited: 'fa fa-ep-groups',
  pg_treatment_protocol_step_missed: 'fa fa-ep-groups',
};

const getIconType = (type, target, isDropdownView) => {
  switch (type) {
    case 'diagnosis_encouragement':
      return renderEncourageIcon(target.additional_data.milestone, true);
    case 'media_encouragement':
      return renderEncourageIcon(target.additional_data.milestone);
    case 'self_birthday_event':
      return <img src={congratIcon} className="congrats-icon" alt="congratulation-icon" loading="lazy" />;
    case 'lowest_mortality_event':
      return <img src={trophyIcon} className="trophy-icon" alt="congratulation-icon" loading="lazy" />;
    case 'api_sync':
      return <img src={cloudUploadIcon} alt="cloud-upload" loading="lazy" />;
    case 'group_import':
    case 'loading_pig_group_created':
    case 'loading_pig_group_expected':
    case 'change_pig_group_name':
    case 'farm_compliance':
    case 'health_alert':
    case 'feed_order_submitted':
    case 'water_usage_warning_event':
    case 'general_water_usage_warning_event':
    case 'general_withdrawal_warning_event':
    case 'svr_compliance':
    case 'svr_created_alert':
    case 'svr_question_fixed_alert':
    case 'withdrawal_warning_event':
    case 'pg_treatment_protocol_created':
    case 'pg_treatment_protocol_edited':
    case 'pg_treatment_protocol_step_missed':
    case 'out_of_compliance_groups_alert':
      return <i className={cn('notify-icon', iconForType[type], { isDropdownView })} />;
    case 'svr_note_mention':
      return (
        <div className={cn('svr-mention fa fa-site-visit-report', { isDropdownView })}>
          <i className="dc-mention-icon fa fa-at" />
        </div>
      );
    case 'dc_mention':
      return (
        <div className={cn('dc-mention fa fa-ep-groups', { isDropdownView })}>
          <i className="dc-mention-icon fa fa-at" />
        </div>
      );
    case 'loading_edited_confirmation_production':
    case 'loading_edited_production':
    case 'sales_result_created_production':
    case 'loading_created_production':
    case 'loading_canceled_production':
      return <LogisticsNotificationIcon target={target} type={type} isDropdownView={isDropdownView} />;
    default: return '';
  }
};

const getRespondButtonTitle = (type, target) => {
  switch (type) {
    case 'diagnosis_encouragement':
    case 'media_encouragement':
    case 'birthday_event':
    case 'lowest_mortality_event':
    case 'group_import':
    case 'open_group':
    case 'diagnosis_alert':
    case 'health_alert':
    case 'change_pig_group_name':
    case 'mention':
    case 'svr_mention':
    case 'svr_note_mention':
    case 'dc_mention':
    case 'tenant_resource':
    case 'loading_force_accepted_production':
    case 'loading_pig_group_created':
    case 'loading_pig_group_expected':
    case 'out_of_compliance_groups_alert':
      return 'general.roles.viewMore';
    case 'ff_comment':
    case 'direct_post':
      if (!isEmpty(target?.company)) return 'general.roles.viewMore';
      return 'general.respond';
    case 'svr_compliance':
    case 'farm_compliance':
      return 'general.button.submit';
    case 'request_close_group':
      return 'general.manageGroup';
    case 'sales_result_created_production':
      return 'general.reviewResults';
    case 'loading_created_production':
    case 'loading_edited_production':
    case 'pg_treatment_protocol_created':
    case 'pg_treatment_protocol_edited':
    case 'pg_treatment_protocol_step_missed':
      return 'general.reviewDetails';
    case 'svr_created_alert':
    case 'svr_question_fixed_alert':
    case 'api_sync':
      return 'general.button.viewReport';
    case 'loading_edited_confirmation_production':
      return 'general.reviewDetailsAndConfirm';
    default:
      return 'general.respond';
  }
};

const getContentMessage = (event_type, target, options) => {
  const renderMessage = (content) => (
    <div className="notification-content">
      <em>
        <Translation text={content}>{renderMentionsText(content)}</Translation>
      </em>
    </div>
  );

  const renderUntranslatedMessage = (content) => (
    <div className="notification-content">
      <em>
        {renderMentionsText(content)}
      </em>
    </div>
  );

  const renderDiagnoses = (diagnoses) => (
    <div className="notification-content">
      {diagnoses.map((diagnosis, index) => (
        <div key={index} className="diagnoses">
          <div><FormattedMessage id="general.diagnosis" /> • <strong>{diagnosis.name}</strong></div>
          <div>
            <FormattedMessage id="general.type" /> • <strong>{diagnosesTypes[diagnosis.diagnosis_type]}</strong>
          </div>
        </div>
      ))}
    </div>
  );

  switch (event_type) {
    case 'ff_comment': return renderMessage(target?.comment || '');
    case 'direct_post': return renderMessage(target?.message || '');
    case 'dc_mention': {
      const { comment } = getCommentByRole(target.comments || [], options.comment_role);
      return renderMessage(comment || '');
    }
    case 'svr_mention':
    case 'svr_note_mention':
      return renderUntranslatedMessage(target?.message || target?.comment || target?.value || '');
    case 'mention':
      return renderMessage((target?.message || '') || (target?.comment || ''));
    case 'diagnosis_alert': return renderDiagnoses(target?.diagnoses || []);
    default: return null;
  }
};

const NotificationItemBody = ({ options: { id, avatarSize, read, initiator, type, target, handleDismissAction,
  created_at, options }, user, className = '', hasTopDivider, isDropdownView }) => {
  const url = getRespondUrl(type, target, user);
  const respondButtonTitle = getRespondButtonTitle(type, target);
  const selfBirthday = (type === 'birthday_event') && (user.id === initiator.id);
  const actualType = selfBirthday ? 'self_birthday_event' : type;
  return (
    <div className={cn('NotificationItemBody', { hasTopDivider, [className]: !!className })}>
      <div className="content-block">
        <div className="notification-icon-block">
          {typesWithAvatars.includes(actualType)
            ? <UserAvatar size={avatarSize} user={initiator} notificationType={actualType} />
            : getIconType(actualType, target, isDropdownView)}
        </div>
        <div className="notification-block">
          <div className="notification-caption">
            {getNotificationCaption(type, target, initiator, options, user)}
          </div>
          {getContentMessage(type, target, options)}
          <div className={cn('notification-footer', { 'desktop': !isMobile })}>
            <div className="notification-time">
              <TimeAgo date={created_at} /> &nbsp;•&nbsp;
              <FormattedMessage id={`general.${read ? 'read' : 'unread'}`} />
            </div>
            {!isMobile && (
              <div className="notification-actions">
                {url && (
                  <span>
                    <Link className="primary-link" to={url}>
                      <FormattedMessage id={respondButtonTitle} />
                    </Link>
                    &nbsp;•&nbsp;
                  </span>
                )}
                <span onClick={() => handleDismissAction(id, read)}>
                  <FormattedMessage id="general.dismiss" />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {isMobile && (
        <div className="mobile-actions">
          {url && (
            <Link className="button light" to={url}>
              <FormattedMessage id={respondButtonTitle} />
            </Link>
          )}
          <Button onClick={() => handleDismissAction(id, read)} light>
            <FormattedMessage id="general.dismiss" />
          </Button>
        </div>
      )}
    </div>
  );
};

NotificationItemBody.propTypes = {
  options: T.object,
  user: T.object,
  className: T.string,
  hasTopDivider: T.bool,
  isDropdownView: T.bool,
};

export default NotificationItemBody;
