import React, { Fragment } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
// components
import { FormattedMessage } from 'react-intl';
import Field from 'components/Field';
import Button from 'components/Button';
import ToggleSwitcher from 'components/ToggleSwitcher/index';
// utils
import get from 'lodash.get';
import classnames from 'classnames/bind';
// styles
import styles from './PrairieSystemsForm.module.scss';


const cn = classnames.bind(styles);

const labels = {
  headTreated: <FormattedMessage id="general.headTreated" />,
  estAvgWeight: <FormattedMessage id="general.estAvgWeight" />,
  sales: <FormattedMessage id="component.prairieSystemsForm.sales" />,
  saleResults: <FormattedMessage id="component.prairieSystemsForm.saleResults" />,
  transfers: <FormattedMessage id="component.prairieSystemsForm.transfers" />,
  pigPlacements: <FormattedMessage id="component.prairieSystemsForm.pigPlacements" />,
  mortalityEvents: <FormattedMessage id="component.prairieSystemsForm.mortalityEvents" />,
  importPigGroups: <FormattedMessage id="component.prairieSystemsForm.importPigGroups" />,
  withdrawalPeriods: <FormattedMessage id="component.prairieSystemsForm.withdrawalPeriods" />,
  feedBasedMedications: <FormattedMessage id="component.prairieSystemsForm.feedBasedMedications" />,
  unitsAdminstrated: <FormattedMessage id="container.checkupEdit.reportMedications.unitsAdministered" />,
  divideMortalities: <FormattedMessage id="component.prairieSystemsForm.divideMortalities" />,
  importingBins: <FormattedMessage id="component.prairieSystemsForm.importingBins" />,
  feedOrders: <FormattedMessage id="general.feedOrders" />,

  dataPushed: <FormattedMessage id="component.prairieSystemsForm.dataPushed" />,
  dataPulled: <FormattedMessage id="component.prairieSystemsForm.dataPulled" />,

};

const dataPushed = [
  {
    key: 'pushed-0',
    text: labels.pigPlacements,
    name: 'export_placements',
  },
  {
    key: 'pushed-1',
    text: labels.transfers,
    name: 'export_transfers',
  },
  {
    key: 'pushed-2',
    text: labels.sales,
    name: 'export_sales',
    nested: [{
      key: 'pushed-3',
      text: labels.saleResults,
      name: 'export_sale_results',
      description: <FormattedMessage id="component.prairieSystemsForm.saleResults.desc" />,
      isDisabled: ({ isLogisticsEnabled, export_sales }) => !(isLogisticsEnabled && export_sales)
    }]
  },
  {
    key: 'pushed-4',
    text: labels.mortalityEvents,
    name: 'export_mortalities',
    nested: [{
      text: labels.divideMortalities,
      name: 'export_death_types',
    }],
  },
  {
    key: 'pushed-5',
    text: labels.estAvgWeight,
    name: 'export_awg_weight',
  },
  {
    key: 'pushed-6',
    text: labels.feedOrders,
    name: 'export_feed_orders',
    description: <FormattedMessage id="component.prairieSystemsForm.feedOrders.desc" />,
  },
];

const dataPulled = [
  {
    key: 'pulled-0',
    text: labels.feedBasedMedications,
    name: 'import_treatments',
  },
  {
    key: 'pulled-1',
    text: labels.importPigGroups,
    name: 'import_pig_groups',
  },
  {
    key: 'pulled-2',
    text: labels.headTreated,
    name: 'import_head_treated',
  },
  {
    key: 'pulled-3',
    text: labels.withdrawalPeriods,
    name: 'import_withdrawal',
  },
  {
    key: 'pulled-4',
    text: labels.importingBins,
    name: 'import_bins',
    description: <FormattedMessage id="component.prairieSystemsForm.importingBins.desc" />,
  }
];

const Box = ({ title, children, className }) => (
  <div className={cn('box-group', 'mt-20 mb-20', className)}>
    <div className={cn('side-name')}>
      <span className={cn('rotated')}>
        {title}
      </span>
    </div>
    <div className={cn('box-body')}>
      {children}
    </div>
  </div>
);

Box.propTypes = {
  title: T.oneOfType([T.string, T.object]).isRequired,
  children: T.node.isRequired,
  className: T.string,
};

const PrairieSystemsForm = ({
  change,
  passwordHint,
  handleForm,
  resetForm,
  handleSubmit,
  pristine,
  submitting,
  formValues,
  connected,
  switchToggle,
  isLogisticsEnabled
}) => {
  const isSubmitDisabled = pristine || submitting || !formValues.user_name.trim() ||
    !formValues.application_id.trim() || !formValues.token_id.trim();
  const onSubmit = (data) => handleForm(data, change);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn('prairie-systems-form')}>

      <Field
        placeholderKey="general.username"
        name="user_name"
        label={<FormattedMessage id="component.prairieSystemsForm.name" />}
      />

      <Field
        placeholderKey={!passwordHint ? 'general.typePassword' : ''}
        placeholder={passwordHint}
        label={<FormattedMessage id="component.prairieSystemsForm.password" />}
        name="password"
      />

      <Field
        label={<FormattedMessage id="component.prairieSystemsForm.tokenID" />}
        placeholderKey="component.prairieSystemsForm.tokenID"
        name="token_id"
      />

      <Field
        label={<FormattedMessage id="component.prairieSystemsForm.appID" />}
        placeholderKey="component.prairieSystemsForm.appID"
        name="application_id"
      />
      <Box
        className={cn({ disabled: !connected })}
        title={labels.dataPushed}
      >
        {dataPushed.map(({ text, name, key, description, nested: nestedItems = [] }) => (
          <Fragment key={key}>
            <ToggleSwitcher
              checked={get(formValues, name, true)}
              text={text}
              description={description}
              onChange={({ target: { checked } }) => switchToggle(name, checked)}
              isDisabled={!connected}
            />

            {nestedItems.map((item, idx) => (
              <div key={idx} className={cn('nested-switсher')}>
                <ToggleSwitcher
                  checked={get(formValues, item.name, true)}
                  text={item.text}
                  description={item?.description}
                  onChange={({ target: { checked } }) => switchToggle(item.name, checked)}
                  isDisabled={
                    !connected
                    || !get(formValues, name, true)
                    || item.isDisabled?.({ ...formValues, isLogisticsEnabled })
                  }
                />
              </div>
            ))}
          </Fragment>
        ))}
      </Box>
      <Box
        className={cn({ disabled: !connected })}
        title={labels.dataPulled}
      >
        {dataPulled.map(({ text, name, key, description }) => (
          <ToggleSwitcher
            key={key}
            checked={get(formValues, name, true)}
            text={text}
            description={description}
            onChange={({ target: { checked } }) => switchToggle(name, checked)}
            isDisabled={!connected}
          />
        ))}
      </Box>

      <div className="mt-10">
        <Button type="submit" primary className={cn('wider', 'btn-item')} disabled={isSubmitDisabled}>
          <FormattedMessage id={`general.button.${!connected ? 'connect' : 'save'}`} />
        </Button>
        <Button type="reset" light className={cn('wider', 'btn-item')} onClick={resetForm}>
          <FormattedMessage id="general.button.reset" />
        </Button>
      </div>
    </form>
  );
};

PrairieSystemsForm.propTypes = {
  change: T.func.isRequired,
  handleForm: T.func.isRequired,
  resetForm: T.func.isRequired,
  handleSubmit: T.func.isRequired,
  passwordHint: T.string,
  formValues: T.object,
  connected: T.bool.isRequired,
  switchToggle: T.func.isRequired,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
  isLogisticsEnabled: T.bool.isRequired
};

const formComponent = reduxForm({
  form: 'prairie-systems-form',
  enableReinitialize: true,
})(PrairieSystemsForm);

export default connect(
  (state) => ({
    formValues: getFormValues('prairie-systems-form')(state),
    isLogisticsEnabled: state.auth.user.current_company.logistics,
  })
)(formComponent);
