/* eslint no-param-reassign: 0 */
import { createAction, handleActions } from 'redux-actions';
import update from 'react-addons-update';
import { isFakeId, getCheckupOffline, updateCheckupOffline } from 'utils/offlineHelper';
import { updateFarmGroup } from './checkupFarmGroups';
import { getCheckup, getCheckupLogs, reportMigrations, reportMigrationsOffline, reportDeadPigs, updateCheckupAsset,
  reportDeadPigsOffline, reportTreatmentProducts, reportTreatmentProductsOffline, reportCheckupSymptoms, reportWater,
  reportCheckupSymptomsOffline, reportTemps, reportTempsOffline, reportCheckupSVRQuestionAnswer, reportWaterOffline,
  addCheckupComment, addCheckupCommentOffline, submitDailyCheckup, submitDailyCheckupOffline, deleteCheckupAsset,
  deleteCheckupAssetOffline, updateCheckupTreatmentEfficacy, updateCheckupCorrections, updateCheckupAlert,
  updateCheckupSurveys, reportFeedOrderAPI,
} from 'endpoints/checkup/checkupEdit';
import { getTemperaturePoints, getTemperaturePointsOffline } from 'endpoints/common';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  isLoading: false,
  isReportingDeaths: false,
  isReportingEfficacy: false,
  isReportingMovements: false,
  isReportingTreatments: false,
  isReportingSymptoms: false,
  isReportingSVRQuestionAnswer: false,
  isReportingSurvey: false,
  isReportingTemps: false,
  isReportingWater: false,
  isReportingFeedOrder: false,
  resource: {},
  logs: [],
  temperaturePoints: [],
};

export const SET_CHECKUP = 'checkupEdit/SET_CHECKUP';

const RESET_CHECKUP = 'checkupEdit/RESET_CHECKUP';

const SET_LOADING = 'checkupEdit/SET_LOADING';

const FETCH_CHECKUP = 'checkupEdit/FETCH_CHECKUP';
const [FETCH_CHECKUP_PENDING, FETCH_CHECKUP_FULFILLED, FETCH_CHECKUP_REJECTED] = [
  `${FETCH_CHECKUP}_PENDING`,
  `${FETCH_CHECKUP}_FULFILLED`,
  `${FETCH_CHECKUP}_REJECTED`,
];

const FETCH_SET_POINTS = 'checkupEdit/FETCH_SET_POINTS';
const [FETCH_SET_POINTS_PENDING, FETCH_SET_POINTS_FULFILLED, FETCH_SET_POINTS_REJECTED] = [
  `${FETCH_SET_POINTS}_PENDING`,
  `${FETCH_SET_POINTS}_FULFILLED`,
  `${FETCH_SET_POINTS}_REJECTED`,
];

const FETCH_CHECKUP_LOGS = 'checkupEdit/FETCH_CHECKUP_LOGS';
const [FETCH_CHECKUP_LOGS_PENDING, FETCH_CHECKUP_LOGS_FULFILLED, FETCH_CHECKUP_LOGS_REJECTED] = [
  `${FETCH_CHECKUP_LOGS}_PENDING`,
  `${FETCH_CHECKUP_LOGS}_FULFILLED`,
  `${FETCH_CHECKUP_LOGS}_REJECTED`,
];

const SUBMIT_CHECKUP = 'checkupEdit/SUBMIT_CHECKUP';
const [SUBMIT_CHECKUP_PENDING, SUBMIT_CHECKUP_FULFILLED, SUBMIT_CHECKUP_REJECTED] = [
  `${SUBMIT_CHECKUP}_PENDING`,
  `${SUBMIT_CHECKUP}_FULFILLED`,
  `${SUBMIT_CHECKUP}_REJECTED`,
];

const REPORT_PIGS = 'checkupEdit/REPORT_PIGS';
const [REPORT_PIGS_PENDING, REPORT_PIGS_FULFILLED, REPORT_PIGS_REJECTED] = [
  `${REPORT_PIGS}_PENDING`,
  `${REPORT_PIGS}_FULFILLED`,
  `${REPORT_PIGS}_REJECTED`,
];

const REPORT_DEATH = 'checkupEdit/REPORT_DEATH';
const [REPORT_DEATH_PENDING, REPORT_DEATH_FULFILLED, REPORT_DEATH_REJECTED] = [
  `${REPORT_DEATH}_PENDING`,
  `${REPORT_DEATH}_FULFILLED`,
  `${REPORT_DEATH}_REJECTED`,
];

const REPORT_TREATMENTS = 'checkupEdit/REPORT_TREATMENTS';
const [REPORT_TREATMENTS_PENDING, REPORT_TREATMENTS_FULFILLED, REPORT_TREATMENTS_REJECTED] = [
  `${REPORT_TREATMENTS}_PENDING`,
  `${REPORT_TREATMENTS}_FULFILLED`,
  `${REPORT_TREATMENTS}_REJECTED`,
];

const REPORT_SYMPTOMS = 'checkupEdit/REPORT_SYMPTOMS';
const [REPORT_SYMPTOMS_PENDING, REPORT_SYMPTOMS_FULFILLED, REPORT_SYMPTOMS_REJECTED] = [
  `${REPORT_SYMPTOMS}_PENDING`,
  `${REPORT_SYMPTOMS}_FULFILLED`,
  `${REPORT_SYMPTOMS}_REJECTED`,
];

const REPORT_SURVEY = 'checkupEdit/REPORT_SURVEY';
const [REPORT_SURVEY_PENDING, REPORT_SURVEY_FULFILLED, REPORT_SURVEY_REJECTED] = [
  `${REPORT_SURVEY}_PENDING`,
  `${REPORT_SURVEY}_FULFILLED`,
  `${REPORT_SURVEY}_REJECTED`,
];

const REPORT_TEMPS = 'checkupEdit/REPORT_TEMPS';
const [REPORT_TEMPS_PENDING, REPORT_TEMPS_FULFILLED, REPORT_TEMPS_REJECTED] = [
  `${REPORT_TEMPS}_PENDING`,
  `${REPORT_TEMPS}_FULFILLED`,
  `${REPORT_TEMPS}_REJECTED`,
];

const REPORT_FEED_ORDER = 'checkupEdit/REPORT_FEED_ORDER';
const [REPORT_FEED_ORDER_PENDING, REPORT_FEED_ORDER_FULFILLED, REPORT_FEED_ORDER_REJECTED] = [
  `${REPORT_FEED_ORDER}_PENDING`,
  `${REPORT_FEED_ORDER}_FULFILLED`,
  `${REPORT_FEED_ORDER}_REJECTED`,
];

const REPORT_WATER_PENDING = 'checkupEdit/REPORT_WATER_PENDING';
const REPORT_WATER_FULFILLED = 'checkupEdit/REPORT_WATER_FULFILLED';
const REPORT_WATER_REJECTED = 'checkupEdit/REPORT_WATER_REJECTED';

const UPDATE_NOTE = 'checkupEdit/UPDATE_NOTE';
const [UPDATE_NOTE_PENDING, UPDATE_NOTE_FULFILLED, UPDATE_NOTE_REJECTED] = [
  `${UPDATE_NOTE}_PENDING`,
  `${UPDATE_NOTE}_FULFILLED`,
  `${UPDATE_NOTE}_REJECTED`,
];

const SAVE_TREATMENTS_EFFICACY = 'checkupEdit/SAVE_TREATMENTS_EFFICACY';
const [SAVE_TREATMENTS_EFFICACY_PENDING, SAVE_TREATMENTS_EFFICACY_FULFILLED, SAVE_TREATMENTS_EFFICACY_REJECTED] = [
  `${SAVE_TREATMENTS_EFFICACY}_PENDING`,
  `${SAVE_TREATMENTS_EFFICACY}_FULFILLED`,
  `${SAVE_TREATMENTS_EFFICACY}_REJECTED`,
];

const REPORT_SVR_QUESTION_ANSWER_PENDING = 'checkupEdit/REPORT_SVR_QUESTION_ANSWER_PENDING';
const REPORT_SVR_QUESTION_ANSWER_FULFILLED = 'checkupEdit/REPORT_SVR_QUESTION_ANSWER_FULFILLED';
const REPORT_SVR_QUESTION_ANSWER_REJECTED = 'checkupEdit/REPORT_SVR_QUESTION_ANSWER_REJECTED';

const UPLOAD_ASSET_COMPLETE = 'checkupEdit/UPLOAD_ASSET_COMPLETE';
const UPDATE_ASSET = 'checkupEdit/UPDATE_ASSET';

const UPDATE_ALERT = 'checkupEdit/UPDATE_ALERT';
const [UPDATE_ALERT_PENDING, UPDATE_ALERT_FULFILLED, UPDATE_ALERT_REJECTED] = [
  `${UPDATE_ALERT}_PENDING`,
  `${UPDATE_ALERT}_FULFILLED`,
  `${UPDATE_ALERT}_REJECTED`,
];

// ------------------------------------
// Actions
// ------------------------------------
export const setCheckup = createAction(SET_CHECKUP);

export const resetCheckup = createAction(RESET_CHECKUP);

export const setLoading = createAction(SET_LOADING);

export const fetchCheckup = (id, isEditMode) => (dispatch, getState) => {
  dispatch(createAction(FETCH_CHECKUP_PENDING)());
  const { isOnline } = getState().network;
  return (isOnline ? getCheckup : getCheckupOffline)(id, isEditMode)
    .then((resource = {}) => {
      dispatch(createAction(FETCH_CHECKUP_FULFILLED)({ resource }));
      return resource;
    })
    .catch((error) => {
      dispatch(createAction(FETCH_CHECKUP_REJECTED)(error));
      throw error;
    });
};

export const fetchSetPoints = () => (dispatch, getState) => {
  dispatch(createAction(FETCH_SET_POINTS_PENDING)());
  const { isOnline } = getState().network;
  return (isOnline ? getTemperaturePoints : getTemperaturePointsOffline)()
    .then((resources) => {
      dispatch(createAction(FETCH_SET_POINTS_FULFILLED)({ resources }));
      return resources;
    })
    .catch((response) => {
      dispatch(createAction(FETCH_SET_POINTS_REJECTED)(response));
      throw response;
    });
};

export const fetchCheckupLogs = (id) => (dispatch, getState) => {
  const { isOnline } = getState().network;
  if (!isOnline || isFakeId(id)) return Promise.resolve();

  dispatch(createAction(FETCH_CHECKUP_LOGS_PENDING)());
  return getCheckupLogs(id)
    .then((resources) => {
      dispatch(createAction(FETCH_CHECKUP_LOGS_FULFILLED)({ resources }));
      return resources;
    })
    .catch((response) => {
      dispatch(createAction(FETCH_CHECKUP_LOGS_REJECTED)(response));
      throw response;
    });
};

export const submitCheckup = (id) => (dispatch, getState) => {
  dispatch(createAction(SUBMIT_CHECKUP_PENDING));
  const { isOnline } = getState().network;

  return (isOnline ? submitDailyCheckup : submitDailyCheckupOffline)(id)
    .then((resource) => {

      updateCheckupOffline(resource);

      dispatch(updateFarmGroup({
        ...resource.pig_group,
        current_checkup_submitted: true,
        current_checkup_created_on: resource.created_on,
      }));

      dispatch(createAction(SUBMIT_CHECKUP_FULFILLED)({ resource }));
      return resource;
    })
    .catch((err) => {
      dispatch(createAction(SUBMIT_CHECKUP_REJECTED)(err));
      throw err;
    });
};

export const saveEfficacyResponse = (checkupId, efficacy_id, resource) => createAction(SAVE_TREATMENTS_EFFICACY)(
  updateCheckupTreatmentEfficacy(checkupId, efficacy_id, resource)
);

export const reportPigs = (id, data) => (dispatch, getState) => {
  dispatch(createAction(REPORT_PIGS_PENDING)());
  const { isOnline } = getState().network;

  return (isOnline ? reportMigrations : reportMigrationsOffline)(id, data)
    .then((resource) => {
      dispatch(createAction(REPORT_PIGS_FULFILLED)({ resource }));
      return resource;
    })
    .catch((err) => {
      dispatch(createAction(REPORT_PIGS_REJECTED)(err));
      throw err;
    });
};

export const reportDeath = (id, data) => (dispatch, getState) => {
  dispatch(createAction(REPORT_DEATH_PENDING)());
  const { isOnline } = getState().network;

  return (isOnline ? reportDeadPigs : reportDeadPigsOffline)(id, data)
    .then((resource) => {
      dispatch(createAction(REPORT_DEATH_FULFILLED)({ resource }));
      return resource;
    })
    .catch((err) => {
      dispatch(createAction(REPORT_DEATH_REJECTED)(err));
      throw err;
    });
};

export const reportSurvey = (id, resource) => (dispatch) => {
  dispatch(createAction(REPORT_SURVEY_PENDING)());
  return updateCheckupSurveys(id, resource)
    .then((response) => {
      dispatch(createAction(REPORT_SURVEY_FULFILLED)(response));
    })
    .catch((err) => {
      dispatch(createAction(REPORT_SURVEY_REJECTED)(err));
      throw err;
    });
};

export const reportSVRQuestionAnswer = (checkupId, svrId, resource) => (dispatch) => {
  dispatch(createAction(REPORT_SVR_QUESTION_ANSWER_PENDING)());
  return reportCheckupSVRQuestionAnswer(checkupId, svrId, resource)
    .then((resource) => {
      dispatch(createAction(REPORT_SVR_QUESTION_ANSWER_FULFILLED)({ resource }));
    })
    .catch((err) => {
      dispatch(createAction(REPORT_SVR_QUESTION_ANSWER_REJECTED)(err));
      throw err;
    });
};

export const reportTreatments = (id, data) => (dispatch, getState) => {
  dispatch(createAction(REPORT_TREATMENTS_PENDING)());
  const { isOnline } = getState().network;

  return (isOnline ? reportTreatmentProducts : reportTreatmentProductsOffline)(id, data)
    .then((resource) => {
      dispatch(createAction(REPORT_TREATMENTS_FULFILLED)({ resource }));
      return resource;
    })
    .catch((err) => {
      dispatch(createAction(REPORT_TREATMENTS_REJECTED)(err));
      throw err;
    });
};

export const reportSymptoms = (id, data) => (dispatch, getState) => {
  dispatch(createAction(REPORT_SYMPTOMS_PENDING)());
  const { isOnline } = getState().network;

  return (isOnline ? reportCheckupSymptoms : reportCheckupSymptomsOffline)(id, data)
    .then((resource) => {
      dispatch(createAction(REPORT_SYMPTOMS_FULFILLED)({ resource }));
      return resource;
    })
    .catch((err) => {
      dispatch(createAction(REPORT_SYMPTOMS_REJECTED)(err));
      throw err;
    });
};

export const reportTemperatures = (id, data) => (dispatch, getState) => {
  dispatch(createAction(REPORT_TEMPS_PENDING)());
  const { isOnline } = getState().network;

  return (isOnline ? reportTemps : reportTempsOffline)(id, data)
    .then((resource) => {
      dispatch(createAction(REPORT_TEMPS_FULFILLED)({ resource }));
      return resource;
    })
    .catch((err) => {
      dispatch(createAction(REPORT_TEMPS_REJECTED)(err));
      throw err;
    });
};

export const reportWaterUsage = (id, data) => (dispatch, getState) => {
  dispatch(createAction(REPORT_WATER_PENDING)());
  const { isOnline } = getState().network;

  return (isOnline ? reportWater : reportWaterOffline)(id, data)
    .then((resource) => {
      dispatch(createAction(REPORT_WATER_FULFILLED)({ resource }));
      return resource;
    })
    .catch((err) => {
      dispatch(createAction(REPORT_WATER_REJECTED)(err));
      throw err;
    });
};

export const reportFeedOrder = (id, data) => (dispatch) => {
  dispatch(createAction(REPORT_FEED_ORDER_PENDING)());

  return reportFeedOrderAPI(id, data)
    .then((resource) => {
      dispatch(createAction(REPORT_FEED_ORDER_FULFILLED)({ resource }));
      return resource;
    })
    .catch((err) => {
      dispatch(createAction(REPORT_FEED_ORDER_REJECTED)(err));
      throw err;
    });
};

export const updateCheckupNote = (id, data) => (dispatch, getState) => {
  dispatch(createAction(UPDATE_NOTE_PENDING)());
  const { isOnline } = getState().network;

  return (isOnline ? addCheckupComment : addCheckupCommentOffline)(id, data)
    .then((resource) => {
      dispatch(createAction(UPDATE_NOTE_FULFILLED)({ resource }));
      return resource;
    })
    .catch((err) => {
      dispatch(createAction(UPDATE_NOTE_REJECTED)(err));
      throw err;
    });
};

export const uploadAssetComplete = (asset) => (dispatch, getState) => {
  const { resource } = getState().dailyCheckup.checkupEdit;
  const newCheckup = {
    ...resource,
    dc_assets: [asset, ...resource.dc_assets]
  };
  updateCheckupOffline(newCheckup);
  dispatch(createAction(UPLOAD_ASSET_COMPLETE)(asset));
};

export const updateAsset = (id, assetId, data) => (dispatch) => {
  return updateCheckupAsset(id, assetId, data)
    .then((resource) => {
      dispatch(createAction(SET_CHECKUP)(resource)); // resource here is a daily checkup
    });
};

// update checkup from response using "FETCH_CHECKUP" action
export const deleteAsset = (id, assetId) => (dispatch, getState) => {
  dispatch(createAction(FETCH_CHECKUP_PENDING));
  const { isOnline } = getState().network;
  return (isOnline ? deleteCheckupAsset : deleteCheckupAssetOffline)(id, assetId)
    .then((resource) => {
      return dispatch(createAction(FETCH_CHECKUP_FULFILLED)({ resource }));
    })
    .catch((err) => {
      dispatch(createAction(FETCH_CHECKUP_REJECTED)(err));
      throw err;
    });
};

export const updateAlert = (dcId, alertId, resource) => createAction(UPDATE_ALERT)(
  updateCheckupAlert(dcId, alertId, resource)
);

export const makeCheckupCorrection = (id, data) => (dispatch) => {
  dispatch(setLoading(true));
  return updateCheckupCorrections(id, data)
    .then(() => {
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      throw error;
    });
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({

  // FETCH_CHECKUP
  [FETCH_CHECKUP_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_CHECKUP_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  [FETCH_CHECKUP_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),

  // FETCH_SET_POINTS
  [FETCH_SET_POINTS_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_SET_POINTS_FULFILLED]: (state, action) => ({
    ...state,
    temperaturePoints: action.payload.resources,
    isLoading: false,
  }),
  [FETCH_SET_POINTS_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),

  // FETCH_CHECKUP_LOGS
  [FETCH_CHECKUP_LOGS_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_CHECKUP_LOGS_FULFILLED]: (state, action) => ({
    ...state,
    logs: action.payload.resources,
    isLoading: false,
  }),
  [FETCH_CHECKUP_LOGS_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),

  // SUBMIT_CHECKUP
  [SUBMIT_CHECKUP_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [SUBMIT_CHECKUP_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  [SUBMIT_CHECKUP_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),

  // SET_CHECKUP, SET_LOADING
  [SET_CHECKUP]: (state, action) => ({
    ...state,
    isLoading: false,
    resource: action.payload,
  }),
  [RESET_CHECKUP]: () => ({
    ...initialState,
  }),
  [SET_LOADING]: (state, action) => ({
    ...state,
    isLoading: action.payload,
  }),

  // REPORT_PIGS
  [REPORT_PIGS_PENDING]: (state) => ({
    ...state,
    isReportingMovements: true,
  }),
  [REPORT_PIGS_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isReportingMovements: false,
  }),
  [REPORT_PIGS_REJECTED]: (state) => ({
    ...state,
    isReportingMovements: false,
  }),

  // REPORT_DEATH
  [REPORT_DEATH_PENDING]: (state) => ({
    ...state,
    isReportingDeaths: true,
  }),
  [REPORT_DEATH_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isReportingDeaths: false,
  }),
  [REPORT_DEATH_REJECTED]: (state) => ({
    ...state,
    isReportingDeaths: false,
  }),

  // REPORT_TREATMENTS
  [REPORT_TREATMENTS_PENDING]: (state) => ({
    ...state,
    isReportingTreatments: true,
  }),
  [REPORT_TREATMENTS_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isReportingTreatments: false,
  }),
  [REPORT_TREATMENTS_REJECTED]: (state) => ({
    ...state,
    isReportingTreatments: false,
  }),

  // REPORT_TEMPS
  [REPORT_TEMPS_PENDING]: (state) => ({
    ...state,
    isReportingTemps: true,
  }),
  [REPORT_TEMPS_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isReportingTemps: false,
  }),
  [REPORT_TEMPS_REJECTED]: (state) => ({
    ...state,
    isReportingTemps: false,
  }),

  // REPORT_WATER_USAGE
  [REPORT_WATER_PENDING]: (state) => ({
    ...state,
    isReportingWater: true,
  }),
  [REPORT_WATER_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isReportingWater: false,
  }),
  [REPORT_WATER_REJECTED]: (state) => ({
    ...state,
    isReportingWater: false,
  }),

  // REPORT_FEED_ORDER
  [REPORT_FEED_ORDER_PENDING]: (state) => ({
    ...state,
    isReportingFeedOrder: true,
  }),
  [REPORT_FEED_ORDER_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isReportingFeedOrder: false,
  }),
  [REPORT_FEED_ORDER_REJECTED]: (state) => ({
    ...state,
    isReportingFeedOrder: false,
  }),

  // UPDATE NOTE
  [UPDATE_NOTE_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [UPDATE_NOTE_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  [UPDATE_NOTE_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),

  // SAVE_TREATMENTS_EFFICACY
  [SAVE_TREATMENTS_EFFICACY_PENDING]: (state) => ({
    ...state,
    isReportingEfficacy: true,
  }),
  [SAVE_TREATMENTS_EFFICACY_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isReportingEfficacy: false,
  }),
  [SAVE_TREATMENTS_EFFICACY_REJECTED]: (state) => ({
    ...state,
    isReportingEfficacy: false,
  }),

  [REPORT_SVR_QUESTION_ANSWER_PENDING]: (state) => ({
    ...state,
    isReportingSVRQuestionAnswer: true,
  }),
  [REPORT_SVR_QUESTION_ANSWER_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isReportingSVRQuestionAnswer: false,
  }),
  [REPORT_SVR_QUESTION_ANSWER_REJECTED]: (state) => ({
    ...state,
    isReportingSVRQuestionAnswer: false,
  }),

  // REPORT_SYMPTOMS
  [REPORT_SYMPTOMS_PENDING]: (state) => ({
    ...state,
    isReportingSymptoms: true,
  }),
  [REPORT_SYMPTOMS_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isReportingSymptoms: false,
  }),
  [REPORT_SYMPTOMS_REJECTED]: (state) => ({
    ...state,
    isReportingSymptoms: false,
  }),

  // REPORT_SURVEYS
  [REPORT_SURVEY_PENDING]: (state) => ({
    ...state,
    isReportingSurvey: true,
  }),

  [REPORT_SURVEY_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isReportingSurvey: false,
  }),

  [REPORT_SURVEY_REJECTED]: (state) => ({
    ...state,
    isReportingSurvey: false,
  }),

  // UPLOAD_ASSET_COMPLETE
  [UPLOAD_ASSET_COMPLETE]: (state, action) => ({
    ...state,
    resource: {
      ...state.resource,
      updated_at: new Date(),
      dc_assets: [
        action.payload,
        ...state.resource.dc_assets,
      ],
    },
  }),

  // UPDATE_ASSET
  [UPDATE_ASSET]: (state, action) => {
    const assets = state.resource.dc_assets;
    const index = assets.indexOf(assets.find(({ id }) => id === action.payload.id));
    return update(state, {
      resource: {
        dc_assets: {
          [index]: {
            $set: action.payload,
          },
        },
      },
    });
  },

  // UPDATE_ALERT
  [UPDATE_ALERT_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [UPDATE_ALERT_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  [UPDATE_ALERT_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
